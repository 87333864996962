/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head } from 'lodash'

import { organisationList } from '../actions/organisation'

import { default_theme as theme } from '../emotion/theme'

class ToggleButton extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(organisationList.fetchListIfNeeded())
    }

        onClick = (event) => {
        const { onChange, value } = this.props
        if(onChange) {
            onChange(!value)
        }
    }

    setLabel() {
        const { value, on_label, off_label } = this.props
        
        let label
        if (value) {
            label = on_label || "on"
        } else {
            label = off_label || "off"
        }
        
        return label
    }
    
    render() {
        const { value, is_primary_light } = this.props

        const label = this.setLabel()
        
        return (
            <div onClick={this.onClick}>
              <div css={ container }>
                <div className="toggle-button__input"
                     css={ value ?
                           [enabled,
                            css`background:${is_primary_light ? theme.colors.dark : theme.colors.light};
                                &:after{background:${is_primary_light ? 'black' : 'white'}}`]
                         : [disabled,
                            css`background:${is_primary_light ? theme.colors.dark : theme.colors.light};`] }></div>
                <div className="toggle-button__label"
                     css={ [label_container,
                            css`color:${is_primary_light ? 'black' : 'white'};`] }>{label}</div>
              </div>
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    const organisation = head(organisationList.getVisibleObjects()) || {}
    return {
        is_primary_light: organisation.is_mobile_primary_colour_light,
    }
}

export default connect(mapStateToProps)(ToggleButton)

const container = css`
height: 100%;
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: center;
`

const enabled = css`
position: relative;
display: inline-block;
height: 15px;
width: 33px;
border-radius: 150px;
cursor: pointer;
transition: all 0.3s ease;
vertical-align: middle;
margin: 0 18px;
&:after {
    position: absolute;
    left: -2px;
    top: -2px;
    display: block;
    width: 19px;
    height: 19px;
    border-radius: 150px;
    box-shadow: 0 0 1.5px 0 rgba(0, 0, 0, 0.12), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.24);
    content: '';
    transition: all 0.3s ease;
    left: 15px;
}
`

const disabled = css`
position: relative;
display: inline-block;
height: 15px;
width: 33px;
border-radius: 150px;
cursor: pointer;
transition: all 0.3s ease;
vertical-align: middle;
margin: 0 18px;
&:after {
    position: absolute;
    left: -2px;
    top: -2px;
    display: block;
    width: 19px;
    height: 19px;
    border-radius: 100px;
    background: #d6d2d2;
    box-shadow: 0 0 1.5px 0 rgba(0, 0, 0, 0.12), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.24);
    content: '';
    transition: all 0.3s ease;

}
`

const label_container = css`
padding-top: 5px;
display: inline-block;
height: 150px;
font-family: 'Montserrat', sans-serif;
text-transform: uppercase;
font-weight: 600;
letter-spacing: 2.5px;
font-size: 16px;
@media(min-height: 1025px) {
padding-top: 10px;
font-size: 24px;
letter-spacing: 5px;
}
`
