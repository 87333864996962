/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, get, split, map } from 'lodash'

import MainLayout from './MainLayout'
import MenuTop from './MenuTop'
import MenuBottom from './MenuBottom'
import DefaultButton from './DefaultButton'
import BusyMask from './BusyMask'

import { organisationList } from '../actions/organisation'
import { imageList } from '../actions/image'

import { default_theme as theme } from '../emotion/theme'

class DonationComplete extends Component {
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(organisationList.fetchListIfNeeded())
        dispatch(imageList.fetchListIfNeeded())
    }

    goHome = () => {
        const { history } = this.props
        history.push('/landing')
    }

    renderDonationSuccess = () => {
        const { organisation } = this.props
        return (
            <div css={ donation_container }>
              <label css={ label }>Donation Successful</label>
              <p css={ paragraph }>
                { `Thank you for donating! Your contribution to ${organisation.name} is truly appreciated.` }
              </p>
            </div>
        )
    }
    
    renderDonationFailure = () => {
        const { organisation } = this.props
        const body_parts = split(organisation.bank_details, "\n")
        return (
            <div css={ donation_container }>
              <label css={ label }>Donation Failed</label>
              <p css={ paragraph }>
                { `Unfortunately your Zapper payment was unsuccessful. If you still intend to donate, you can do so via EFT.` }
              </p>
              <p css={ [ paragraph, css`padding:0;`] }><strong>Bank Details:</strong></p>
              { map(body_parts, function(v, k) {
                    return (<span key={ k }>{ v }</span>)
                })}
              <br />
            </div>
        )
    }

    render() {
        const { organisation_logo, logo_background, history, is_loading, status } = this.props
        return (
            <MainLayout history={ history }>
              { is_loading && <BusyMask /> }
              <div css={ menu_top }>
                <MenuTop
                    page="Feedback"
                    history={ history }
                />
              </div>
              <div css={ content }>
                <div css={ [logo, css`background:${logo_background};
                  @media (prefers-color-scheme: dark) {
                      background:${logo_background} !important;
                  }`] }>
                  <img src={ organisation_logo } alt="" css={ image } />
                </div>
                <div css={ donate_container }>
                  { status === 'success' ? this.renderDonationSuccess() : this.renderDonationFailure() }
                  <div css={ field_container }>
                    <DefaultButton
                        label="Home"
                        onClickButton={ () => this.goHome() }
                    />
                  </div>
                  <div css={ menu_bottom }>
                    <MenuBottom
                        page="Messages"
                    />
                  </div>
                </div>
              </div>
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const organisation = head(organisationList.getVisibleObjects()) || {}
    const status = get(props, ["match", "params", "status"], null)
    return {
        organisation,
        organisation_logo: organisation.thumbnail,
        logo_background: organisation.mobile_logo_background_colour_hex,
        is_loading: organisationList.isLoading() || imageList.isLoading(),
        status,
    }
}
export default connect(mapStateToProps)(DonationComplete)

const menu_top = css`
width: 100%;
height: 60px;
display: flex;
justify-content: center;
align-items: center;
background-color: white;
position: fixed;
left: 0;
top: 0;
z-index: 2;
@media(min-height: 1025px) {
height: 120px;
}
`

const content = css`
width: 100%;
color: black;
margin-top: 60px;
margin-bottom: 60px;
@media(min-height: 1025px) {
margin-top: 120px;
margin-bottom: 120px;
}
`

const logo = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 20vh;
`

const image = css`
max-height: 90%;
max-width: 90%;
object-fit: contain;
`

const donate_container = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: ${theme.colors.light};
`

const label = css`
font-size: 20px;
text-align: center;
text-transform: uppercase;
font-weight: 600;
padding-top: 30px;
padding-bottom: 10px;
`

const menu_bottom = css`
width: 100%;
height: 60px;
display: flex;
align-items: center;
background-color: white;
position: fixed;
left: 0;
bottom: 0;
@media(min-height: 1025px) {
height: 120px;
}
`

const paragraph = css`
text-align: center;
width: 80%;
padding-bottom: 10px;
`

const field_container = css`
width: 60%;
display:flex;
flex-direction: column;
padding: 5px;
padding-bottom: 40px;
`

const donation_container = css`
display: flex;
flex-direction: column;
width: 100%;
align-items: center;
`
