import { ItemList } from '../orm'
import { ENTITY_KEY__EVENT, LIST_KEY__EVENT } from './ItemListKeyRegistry'
import { map } from 'lodash'

class EventList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__EVENT
    }

    getAsCalendarEvents() {
        const events = this.getVisibleObjects()
        const result = map(events, (event) => {
          return { id: event.id, title: event.name,
                   description: event.description, address: event.address,
                   color: event.mobile_event_colour_hex,
                   start: event.event_start, end: event.event_end,
                   allDay: event.is_all_day }
        })
        return result
    }

    saveToGoogleCal = (event) => {
        const base_url = "https://calendar.google.com/calendar/r/eventedit?"
        const start = event.event_start.slice(0, -4).replace(/[\W_]/g, "");
        const end = event.event_end.slice(0, -4).replace(/[\W]/g, '');
        window.open(`${base_url}text=${event.name}&details=${event.description}&location=${event.address}&dates=${start}/${end}`)
    }

    saveToAppleCal = (event) => {
        const start = event.event_start.slice(0, -5).replace(/[\W_]/g, "");
        const end = event.event_end.slice(0, -5).replace(/[\W]/g, '');
        let url = [
            "BEGIN:VCALENDAR",
            "VERSION:2.0",
            "BEGIN:VEVENT",
            "DTSTART:" + start,
            "DTEND:" + end,
            "SUMMARY:" + event.name,
            "DESCRIPTION:" + event.description,
            "LOCATION:" + event.address,
            "BEGIN:VALARM",
            "TRIGGER:-PT15M",
            "REPEAT:1",
            "DURATION:PT15M",
            "ACTION:DISPLAY",
            "DESCRIPTION:Reminder",
            "END:VALARM",
            "END:VEVENT",
            "END:VCALENDAR"
        ].join("\n");
        window.open("data:text/calendar;charset=utf8," + escape(url));
    }
}

export const eventList = new EventList(LIST_KEY__EVENT)
