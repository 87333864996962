/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head } from 'lodash'

import MainLayout from './MainLayout'
import MenuTop from './MenuTop'
import MenuBottom from './MenuBottom'
import FeedbackForm from './FeedbackForm'
import BusyMask from './BusyMask'

import { organisationList, feedbackEmailList } from '../actions/organisation'
import { imageList } from '../actions/image'

import { default_theme as theme } from '../emotion/theme'

class Feedback extends Component {

    constructor(props) {
        super(props)
        this.state = {
            display_footer_menu: true,
        }
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(organisationList.fetchListIfNeeded())
        dispatch(imageList.fetchListIfNeeded())
    }

    handleSubmit = (values) => {
        const { organisation, history, dispatch } = this.props
        values.isNewInstance = true
        values.organisation_id = organisation.id
        const on_done = function() {
            history.push('/feedback_confirmation')
        }
        return dispatch(feedbackEmailList.send(values, on_done))
    }
    
    toggleMenu = () => {
        const { display_footer_menu } = this.state
        this.setState({
            display_footer_menu: !display_footer_menu,
        })
    }

    render() {
        const { organisation_logo, logo_background, history, is_loading } = this.props
        const { display_footer_menu } = this.state
        return (
            <MainLayout history={ history }>
              { is_loading && <BusyMask /> }
              <div css={ menu_top }>
                <MenuTop
                    page="Feedback"
                    history={ history }
                />
              </div>
              <div css={ content }>
                <div css={ [logo, css`background:${logo_background};
                  @media (prefers-color-scheme: dark) {
                      background:${logo_background} !important;
                  }`] }>
                  <img src={ organisation_logo } alt="" css={ image } />
                </div>
                <div css={ donate_container }>
                  <label css={ label }>We'd love to hear from you</label>
                  <p css={ paragraph }>
                    We would like you to share your thoughts and feelings about our app.
                    You're welcome to provide this in the message area below.
                  </p>
                  <FeedbackForm
                      onSubmit={ this.handleSubmit }
                      toggleMenu={ this.toggleMenu }
                  />
                </div>
                { display_footer_menu &&
                  <div css={ menu_bottom }>
                    <MenuBottom
                        page="Messages"
                    />
                  </div>
                }
              </div>
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const organisation = head(organisationList.getVisibleObjects()) || {}
    return {
        organisation,
        organisation_logo: organisation.thumbnail,
        logo_background: organisation.mobile_logo_background_colour_hex,
        is_loading: organisationList.isLoading() || imageList.isLoading(),
    }
}
export default connect(mapStateToProps)(Feedback)

const menu_top = css`
width: 100%;
height: 60px;
display: flex;
justify-content: center;
align-items: center;
background-color: white;
position: fixed;
left: 0;
top: 0;
z-index: 2;
@media(min-height: 1025px) {
height: 120px;
}
`

const content = css`
width: 100%;
color: black;
margin-top: 60px;
margin-bottom: 60px;
@media(min-height: 1025px) {
margin-top: 120px;
margin-bottom: 120px;
}
`

const logo = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 20vh;
`

const image = css`
max-height: 90%;
max-width: 90%;
object-fit: contain;
`

const donate_container = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: ${theme.colors.light};
`

const label = css`
font-size: 20px;
text-align: center;
text-transform: uppercase;
font-weight: 600;
padding-top: 30px;
padding-bottom: 10px;
`

const menu_bottom = css`
width: 100%;
height: 60px;
display: flex;
align-items: center;
background-color: white;
position: fixed;
left: 0;
bottom: 0;
@media(min-height: 1025px) {
height: 120px;
}
`

const paragraph = css`
text-align: center;
width: 80%;
padding-bottom: 10px;
`
