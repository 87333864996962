import { ItemList } from '../orm'
import { ENTITY_KEY__IMAGE, LIST_KEY__IMAGE } from './ItemListKeyRegistry'

class ImageList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__IMAGE
    }
}

export const imageList = new ImageList(LIST_KEY__IMAGE)
