/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field } from 'redux-form'
import { head, isEmpty } from 'lodash'

import InputField from './InputField'
import DefaultButton from './DefaultButton'
import BusyMask from './BusyMask'

import { userList } from '../actions/user'

import { default_theme as theme } from '../emotion/theme'

const required = value => value ? undefined : 'Required'
const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Invalid Email Address' : undefined
const tel = value =>
    value && !(/^\d{7,15}$/).test(value.replace(/[\s()+\-\.]|ext/gi, '')) ?
    'Invalid Phone Number' : undefined

class RegisterForm extends Component {
    
    render() {
        const { handleSubmit, error, toggleMenu, user, is_loading } = this.props
        return (
            <form css={ form_layout } onSubmit={ handleSubmit }>
              { is_loading && <BusyMask /> }
              <div css={ field_container }>
                <Field
                    type="text"
                    name="first_name"
                    component={ InputField }
                    placeholder="First Name"
                    validate={[required]}
                    onFocus={ toggleMenu }
                    onBlur={ toggleMenu }
                />
              </div>
              <div css={ field_container }>
                <Field
                    type="text"
                    name="last_name"
                    component={ InputField }
                    placeholder="Last Name"
                    validate={[required]}
                    onFocus={ toggleMenu }
                    onBlur={ toggleMenu }
                />
              </div>
                <div css={ field_container }>
                  <Field
                      type="email"
                      name="email"
                      component={ InputField }
                      placeholder="Email Address"
                      validate={[email]}
                      onFocus={ toggleMenu }
                      onBlur={ toggleMenu }
                  />
                </div>
              <div css={ field_container }>
                <Field
                    type="tel"
                    name="phone_number"
                    component={ InputField }
                    placeholder="Phone Number"
                    validate={[required, tel]}
                    onFocus={ toggleMenu }
                    onBlur={ toggleMenu }
                />
              </div>
              <div css={ field_container }>
                <DefaultButton
                    label={ isEmpty(user) ? "Sign Up" : "Update"}
                    type="submit"
                />
              </div>
              <div css={ error_container }>
                { error && <p>{ error }</p>}
              </div>
            </form>
        )
    }
}
RegisterForm = reduxForm({
    form: 'REGISTER_FORM'
})(RegisterForm);
function mapStateToProps(state, props) {
    const { onSubmit, toggleMenu, prev_form_values } = props
    const user = head(userList.getVisibleObjects()) || {}
    return {
        onSubmit,
        toggleMenu,
        initialValues: prev_form_values ? prev_form_values : user,
        user,
        is_loading: userList.isLoading()
    }
}
export default withRouter(connect(mapStateToProps)(RegisterForm))

const form_layout = css`
width: 65%;
display: flex;
flex-direction: column;
align-items: center;
`

const field_container = css`
width: 100%;
display:flex;
flex-direction: column;
padding: 5px;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 50px;
flex-direction: column;
color: ${theme.colors.error};
`
