/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, isEmpty, map, size, difference } from 'lodash'

import { userList } from '../actions/user'
import { messageList } from '../actions/message'
import { messageReadList } from '../actions/message_read'
import { organisationList } from '../actions/organisation'
import DefaultIconLabelButton from './DefaultIconLabelButton'
import BusyMask from './BusyMask'

import { default_theme as theme } from '../emotion/theme'
import home_icon from '../images/home_outline_dark.png'
import news_icon from '../images/news_outline_dark.png'
import donate_icon from '../images/donate_outline_dark.png'
import message_icon from '../images/message_outline_dark.png'
import contact_icon from '../images/about_outline_dark.png'
import calendar_icon_dark from '../images/calendar_dark.png'

class MenuBottom extends Component {

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(organisationList.fetchListIfNeeded())
    dispatch(userList.fetchListIfNeeded())
    dispatch(messageList.fetchListIfNeeded())
    dispatch(messageReadList.fetchListIfNeeded())
  }

  componentDidUpdate() {
    const { dispatch } = this.props
    dispatch(messageList.fetchListIfNeeded())
    dispatch(messageReadList.fetchListIfNeeded())
  }

  render() {
    const { unread, page, is_loading, mobile_main_menu_option } = this.props
    return (
      <div css={container}>
        {is_loading && <BusyMask />}
        <div css={menu_container}>
          {page !== 'Home' &&
            <div css={button_container}>
              <DefaultIconLabelButton
                label="Home"
                icon_src={home_icon}
                to="/landing"
                color={theme.colors.dark}
              />
            </div>
          }
          {page !== 'News' &&
            <div css={button_container}>
              <div css={border_left}></div>
              <DefaultIconLabelButton
                label="News"
                icon_src={news_icon}
                to="/news"
                color={theme.colors.dark}
              />
            </div>
          }
          {mobile_main_menu_option === 'events' ? (
            page !== 'Events' &&
            <div css={button_container}>
              <div css={border_left}></div>
              <DefaultIconLabelButton
                label="Events"
                icon_src={calendar_icon_dark}
                to="/events"
                color={theme.colors.dark}
              />
            </div>
          ) : (
            page !== 'Donate' &&
            <div css={button_container}>
              <div css={border_left}></div>
              <DefaultIconLabelButton
                label="Donate"
                icon_src={donate_icon}
                to="/donate"
                color={theme.colors.dark}
              />
            </div>
          )}
          {page !== 'Messages' &&
            <div css={button_container}>
              <div css={border_left}></div>
              <DefaultIconLabelButton
                label="Messages"
                icon_src={message_icon}
                to="/messages"
                unread={unread}
                color={theme.colors.dark}
              />
            </div>
          }
          {page !== 'About' &&
            <div css={button_container}>
              <div css={border_left}></div>
              <DefaultIconLabelButton
                label="About"
                icon_src={contact_icon}
                to="/about"
                color={theme.colors.dark}
              />
            </div>
          }
        </div>
      </div>
    )
  }
}
function mapStateToProps(state, props) {
  const organisation = head(organisationList.getVisibleObjects()) || {}
  const { page } = props
  const user = head(userList.getVisibleObjects()) || {}
  let unread = 0
  if (!isEmpty(user)) {
    const messages = messageList.getVisibleObjects() || []
    const messages_read = messageReadList.getVisibleObjects() || []
    const messages_read_by_user = map(messages_read, (message_read) => {
      return message_read.message
    })
    const all_message_ids = map(messages, (message) => {
      return message.id
    })
    const unread_ids = difference(all_message_ids, messages_read_by_user)
    unread = size(unread_ids)
  }
  return {
    unread,
    page,
    mobile_main_menu_option: organisation.mobile_main_menu_option,
    is_loading: userList.isLoading() || messageList.isLoading() || messageReadList.isLoading()
  }
}
export default connect(mapStateToProps)(MenuBottom)

const container = css`
width: 100%;
display: flex;
justify-content: center;
`

const menu_container = css`
display: flex;
width: 100%;
z-index: 2;
max-width: 1024px;
`

const button_container = css`
width: 25%;
display: flex;
align-items: center;
`

const border_left = css`
border-left: 1px solid #e5e5e5;
height: 50%;
@media(min-height: 1025px) {
height: 70%;
}
`
