/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, get, has, includes } from 'lodash'

import MainLayout from './MainLayout'
import MenuTop from './MenuTop'
import MenuBottom from './MenuBottom'
import RegistrationCompleteForm from './RegistrationCompleteForm'
import BusyMask from './BusyMask'
import DefaultButton from './DefaultButton'

import { organisationList } from '../actions/organisation'
import { imageList } from '../actions/image'
import { announceUserLoggedIn, isLoggedIn, clearAuthentication } from '../actions/auth'
import { userList, otpTextMessageList, userOrganisationList } from '../actions/user'

import { default_theme as theme } from '../emotion/theme'

class RegistrationComplete extends Component {

    constructor(props) {
        super(props)
        this.state = {
            display_footer_menu: true,
            error_message: null,
            message_resent: false,
        }
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(organisationList.fetchListIfNeeded())
        dispatch(imageList.fetchListIfNeeded())
    }

    componentDidUpdate(prevProps) {
        const { dispatch, is_logged_in, history } = this.props
        if (!prevProps.is_logged_in && is_logged_in) {
            history.push('/landing')
        } else if (is_logged_in) {
            dispatch(clearAuthentication())
            dispatch(userList.invalidateList())
        }
    }

    handleSubmit = (values) => {
        const { dispatch, form_values, user_id, psuedo_user_uuid } = this.props
        form_values.user_id = user_id
        form_values.otp = values.otp
        if (psuedo_user_uuid) {
            form_values.psuedo_user_id = psuedo_user_uuid
        }
        const on_done = (res) => {
            if (has(res, 'token')) {
                dispatch(announceUserLoggedIn(res.token, res.user))
                localStorage.removeItem("psuedo_user_uuid")
                Object.keys(localStorage).forEach((key) => {
                    if (includes(key, "psuedo_user_attendence_status_for_event")) {
                      localStorage.removeItem(key)
                    }
                })
            }
        }

        return dispatch(userOrganisationList.verifyPhoneNumber(form_values, on_done))
    }

    onResendMessage = () => {
        const { user_id, dispatch } = this.props
        const that = this
        const on_done = (res) => {
            if (res.status && res.status === 'success') {
                that.setState({message_resent: true})
                that.setState({error_message: null})
            } else {
                that.setState({error_message: 'Verification code could not be sent'})
            }
        }
        return dispatch(otpTextMessageList.send(user_id, on_done))
    }

    onBack = () => {
        const { form_values, history } = this.props
        history.push({
            pathname: '/register',
            state: {
                prev_form_values: form_values,
            }
        })
    }
    
    toggleMenu = () => {
        const { display_footer_menu } = this.state
        this.setState({
            display_footer_menu: !display_footer_menu,
        })
    }

    render() {
        const {
            organisation,
            organisation_logo,
            logo_background,
            history,
            prevPage,
            is_loading,
            phone_number
        } = this.props
        const { display_footer_menu, error_message, message_resent } = this.state
        return (
            <MainLayout history={ history }>
              { is_loading && <BusyMask /> }
              <div css={ menu_top }>
                <MenuTop
                    page={ prevPage }
                    history={ history }
                />
              </div>
              <div css={ content }>
                <div css={ [logo, css`background:${logo_background};
                  @media (prefers-color-scheme: dark) {
                      background:${logo_background} !important;
                  }`] }>
                  <img src={ organisation_logo } alt="" css={ image } />
                </div>
                <div css={ register_container }>
                  <label css={ label }>Finalise { prevPage === "Register" ? "Registration" : "Update"}</label>
                  <p css={ paragraph }>
                    { prevPage === "Register" ? `Welcome to ${organisation.name}. `: ""}
                    A text message has been sent to { phone_number ? phone_number : 'you' } containing a verification code.
                    Please enter the verification code below and press VERIFY.
                  </p>
                  <RegistrationCompleteForm
                      onSubmit={ this.handleSubmit }
                      toggleMenu={ this.toggleMenu }
                  />
                  <div css={ field_container }>
                    <div css={ buttons_container }>
                      <DefaultButton
                          label="Resend"
                          button_type="secondary"
                          onClickButton={ () => this.onResendMessage() }
                          disabled={message_resent}
                          extra_class={button_container}
                      />
                      <DefaultButton
                          label="Back"
                          button_type="secondary"
                          onClickButton={ () => this.onBack() }
                          extra_class={button_container}
                      />
                    </div>
                    <div css={error_container}>
                      {error_message}
                    </div>
                  </div>
                </div>
                { display_footer_menu &&
                  <div css={ menu_bottom }>
                    <MenuBottom
                        page="Messages"
                    />
                  </div>
                }
              </div>
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const is_logged_in = isLoggedIn()
    const organisation = head(organisationList.getVisibleObjects()) || {}
    const psuedo_user_uuid = localStorage.getItem("psuedo_user_uuid")
    return {
        email: props && props.location && props.location.state && props.location.state.email,
        prevPage: props && props.location && props.location.state && props.location.state.prevPage,
        phone_number: props && props.location && props.location.state && props.location.state.phone_number,
        form_values: props && props.location && props.location.state && props.location.state.form_values,
        user_id: get(props, ["match", "params", "user_id"], null),
        psuedo_user_uuid,
        organisation,
        organisation_logo: organisation.thumbnail,
        logo_background: organisation.mobile_logo_background_colour_hex,
        is_logged_in,
        is_loading: organisationList.isLoading() ||
                    imageList.isLoading() ||
                    otpTextMessageList.isLoading() ||
                    userOrganisationList.isLoading() ||
                    userList.isLoading() ||
                    organisationList.getIsSavingObject() ||
                    imageList.getIsSavingObject() ||
                    otpTextMessageList.getIsSavingObject() ||
                    userOrganisationList.getIsSavingObject() ||
                    userList.getIsSavingObject(),
    }
}
export default connect(mapStateToProps)(RegistrationComplete)

const menu_top = css`
width: 100%;
height: 60px;
display: flex;
justify-content: center;
align-items: center;
background-color: white;
position: fixed;
left: 0;
top: 0;
z-index: 2;
@media(min-height: 1025px) {
height: 120px;
}
`

const content = css`
width: 100%;
color: black;
margin-top: 60px;
margin-bottom: 60px;
@media(min-height: 1025px) {
margin-top: 120px;
margin-bottom: 120px;
}
`

const logo = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 20vh;
`

const image = css`
max-height: 90%;
max-width: 90%;
object-fit: contain;
`

const register_container = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: ${theme.colors.light};
`

const field_container = css`
width: 65%;
display:flex;
flex-direction: column;
padding: 5px;
`

const label = css`
font-size: 20px;
text-align: center;
text-transform: uppercase;
font-weight: 600;
padding-top: 30px;
padding-bottom: 10px;
`

const menu_bottom = css`
width: 100%;
height: 60px;
display: flex;
align-items: center;
background-color: white;
position: fixed;
left: 0;
bottom: 0;
@media(min-height: 1025px) {
height: 120px;
}
`

const paragraph = css`
text-align: center;
width: 80%;
padding-bottom: 10px;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;
color: ${theme.colors.error};
`
const buttons_container = css`
display:flex;
flex:1;
justify-content:space-between;
flex-wrap: wrap;
`

const button_container = css`
width:47%;
min-width:130px;
margin-bottom:10px;
@media(max-width: 450px) {
width: 100%;
}`
