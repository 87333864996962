/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field } from 'redux-form'

import InputField from './InputField'
import DefaultButton from './DefaultButton'

import { default_theme as theme } from '../emotion/theme'

const required = value => value ? undefined : 'Required'
const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined

class DonateForm extends Component {
    
    render() {
        const { handleSubmit, error, toggleMenu } = this.props

        return (
            <form css={ form_layout } onSubmit={ handleSubmit }>
              <div css={ field_container }>
                <Field
                    type="tel"
                    name="amount"
                    component={ InputField }
                    validate={[required, number]}
                    onFocus={ toggleMenu }
                    onBlur={ toggleMenu }
                />
              </div>
              <div css={ field_container }>
                <DefaultButton
                    label="Donate"
                    type="submit"
                />
              </div>
              <div css={ error_container }>
                { error && <p>{ error }</p>}
              </div>
            </form>
        )
    }
}
DonateForm = reduxForm({
    form: 'DONATE_FORM'
})(DonateForm);
function mapStateToProps(state, props) {
    const { onSubmit, toggleMenu } = props
    return {
        onSubmit,
        toggleMenu,
    }
}
export default withRouter(connect(mapStateToProps)(DonateForm))

const form_layout = css`
width: 65%;
display: flex;
flex-direction: column;
align-items: center;
`

const field_container = css`
width: 100%;
display:flex;
flex-direction: column;
padding: 5px;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;
color: ${theme.colors.error};
`
