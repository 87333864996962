/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, map, split } from 'lodash'

import MainLayout from './MainLayout'
import MenuTop from './MenuTop'
import MenuBottom from './MenuBottom'
import BusyMask from './BusyMask'

import { organisationList } from '../actions/organisation'
import { imageList } from '../actions/image'

import { default_theme as theme } from '../emotion/theme'
import address_icon from '../images/address_outline_dark.png'
import phone_icon from '../images/phone_number_outline_dark.png'
import mail_icon from '../images/message_outline_dark.png'
import twitter_icon from '../images/twitter_dark.png'
import facebook_icon from '../images/facebook_outline_dark.png'
import website_icon from '../images/website_outline_dark.png'

class About extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displayContent: false
        }
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(organisationList.fetchListIfNeeded())
        dispatch(imageList.fetchListIfNeeded())
    }

    onExpandContent = (e) => {
        e.preventDefault()
        this.toggleContent()
        
    }

    toggleContent = () => {
        const { displayContent } = this.state
        this.setState({displayContent: !displayContent})
    }
    
    render() {
        const { organisation, organisation_logo, logo_background, history, is_loading } = this.props
        const { displayContent } = this.state
        const body_parts = split(organisation.mission, "\n")
        return (
            <MainLayout history={ history }>
              { is_loading && <BusyMask /> }
              <div css={ menu_top }>
                <MenuTop
                    page="About"
                    history={ history }
                />
              </div>
              <div css={ content }>
                <div css={ [logo, css`background:${logo_background};
                  @media (prefers-color-scheme: dark) {
                      background:${logo_background} !important;
                  }`] }>
                  <img src={ organisation_logo } alt="" css={ image } />
                </div>
                <div css={ mission }>
                  <div css={ mission_container }  onClick={ this.onExpandContent }>
                    <label css={ label }>Our Mission</label>
                    { displayContent ?
                      <span css={ our_mission_expanded }>
                        { map(body_parts, function(v, k) {
                              return (<p key={ k }>{ v }</p>)
                          })}
                      </span>
                      :
                      <span css={ our_mission }>
                        { map(body_parts, function(v, k) {
                              return (<p key={ k }>{ v }</p>)
                          })}
                      </span>
                    }
                  </div>
                </div>
                <div css={ contact }>
                  <label css={ label }>Contact</label>
                  { organisation.address &&
                    <div css={ contact_container }>
                      <img src={ address_icon } css={ icon } alt="" />
                      <span css={ contact_text }>{ organisation.address }</span>
                    </div>
                  }
                  { organisation.phone_number &&
                    <a target="_blank"
                        href={ `tel:${organisation.phone_number}` }
                        rel="noopener noreferrer"
                        css={ contact_container }>
                      <img src={ phone_icon } css={ icon } alt="" />
                      <span css={ contact_text }>{ organisation.phone_number }</span>                    
                    </a>
                  }
                  { organisation.email &&
                    <a target="_blank"
                        href={ `mailto:${organisation.email}` }
                        rel="noopener noreferrer"
                        css={ contact_container }>
                      <img src={ mail_icon } css={ icon } alt="" />
                      <span css={ contact_text }>{ organisation.email }</span>                    
                    </a>
                  }
                  { organisation.website &&
                    <a target="_blank"
                        href={ `${organisation.website}` }
                        rel="noopener noreferrer"
                        css={ contact_container }>
                      <img src={ website_icon } css={ icon } alt="" />
                      <span css={ contact_text }>{ organisation.website }</span>                    
                    </a>
                  }
                  { organisation.twitter &&
                    <a target="_blank"
                        href={ `https://twitter.com/${organisation.twitter.substring(1)}` }
                        rel="noopener noreferrer"
                        css={ contact_container }>
                      <img src={ twitter_icon } css={ icon } alt="" />
                      <span css={ contact_text }>{ organisation.twitter }</span>                    
                    </a>
                  }
                  { organisation.facebook &&
                    <a target="_blank"
                        href={ `${organisation.facebook}` }
                        rel="noopener noreferrer"
                        css={ contact_container }>
                      <img src={ facebook_icon } css={ icon } alt="" />
                      <span css={ contact_text }>{ organisation.facebook.split('.com')[1] }</span>
                    </a>
                  }
                </div>
                <hr css={ line } />
                <div css={ registration }>
                  <label css={ label }>Registration</label>
                  <span css={ contact_text }>NPO Number: { organisation.registration_number }</span>
                </div>
              </div>
              <div css={ menu_bottom }>
                <MenuBottom
                    page="About"
                />
              </div>
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const organisation = head(organisationList.getVisibleObjects()) || {}
    return {
        organisation,
        organisation_logo: organisation.thumbnail,
        logo_background: organisation.mobile_logo_background_colour_hex,
        is_loading: organisationList.isLoading() || imageList.isLoading(),
    }
}
export default connect(mapStateToProps)(About)

const menu_top = css`
width: 100%;
height: 60px;
display: flex;
justify-content: center;
align-items: center;
background-color: white;
position: fixed;
left: 0;
top: 0;
z-index: 2;
@media(min-height: 1025px) {
height: 120px;
}
`

const content = css`
width: 100%;
color: black;
margin-top: 60px;
margin-bottom: 60px;
@media(min-height: 1025px) {
margin-top: 120px;
margin-bottom: 120px;
}
`

const logo = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 20vh;
`

const image = css`
max-width: 90%;
max-height: 90%;
object-fit: contain;
`

const mission = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: ${theme.colors.light};
padding-top: 10px;
`

const mission_container = css`
width: 100%;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
height: 90%;
`

const label = css`
font-size: 20px;
text-align: center;
text-transform: uppercase;
font-weight: 600;
`

const our_mission_expanded = css`
font-size: 16px;
text-align: center;
width: 80%;
min-height: 150px;
`

const our_mission = css`
font-size: 16px;
text-align: center;
width: 80%;
max-height: 150px;
overflow: hidden;
position: relative;
&:after {
content: "";
text-align: right;
position: absolute;
bottom: 0;
right: 0;
width: 100%;
height: 40px;
background: linear-gradient(to bottom, rgba(255, 255, 255, 0), ${theme.colors.light} 70%);
}
`

const contact = css`
width: 100%;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
padding-top: 10px;
`

const contact_container = css`
width: 60%;
display: flex;
flex-direction: row;
align-items: center;
padding-bottom: 5px;
@media(min-width: 1025px) {
width: 40%;
}
`

const icon = css`
height: 25px;
padding-right: 10px;
`

const contact_text = css`
font-size: 16px;
`

const line = css`
width: 80%;
`

const registration = css`
width: 100%;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
padding-bottom: 5px;
`

const menu_bottom = css`
width: 100%;
height: 60px;
display: flex;
align-items: center;
background-color: white;
position: fixed;
left: 0;
bottom: 0;
@media(min-height: 1025px) {
height: 120px;
}
`
