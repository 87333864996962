/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'
import { connect } from 'react-redux'
import { head } from 'lodash'

import { organisationList } from '../actions/organisation'

class DefaultToggleButton extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(organisationList.fetchListIfNeeded())
    }
    
    onClick = (event) => {
        const { onChange, value } = this.props
        if(onChange) {
            onChange(!value)
        }
    }

    
    render() {
        const { value, off_label, on_label, secondary, is_secondary_light } = this.props
        const text_color = is_secondary_light ? "black" : "white"
        return (
            <div onClick={this.onClick} css={ container }>
              <div css={ [!value ? css`background:${secondary};
                                       @media (prefers-color-scheme: dark) {
                                         background: ${secondary} !important;
                                       }
                                       color:${text_color};` : disabled, button] }>{ off_label }</div>
              <div css={ [value ? css`background:${secondary};
                                      @media (prefers-color-scheme: dark) {
                                        background: ${secondary} !important;
                                      }
                                      color:${text_color};` : disabled, button] }>{ on_label }</div>
            </div>
        )
    }
}
function mapStateToProps(state, props) {
    const organisation = head(organisationList.getVisibleObjects()) || {}
    return {
        secondary: organisation.mobile_secondary_colour_hex,
        is_secondary_light: organisation.is_mobile_secondary_colour_light,
    }
}
export default connect(mapStateToProps)(DefaultToggleButton)

const container = css`
height: 70%;
width: 90%;
max-width: 500px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
`

const disabled = css`
background:#e5e5e5;
`

const button = css`
width: 50%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
font-family: 'Montserrat', sans-serif;
font-size: 12px;
letter-spacing: 2.6px;
font-weight: 600;
text-transform: uppercase;
`
