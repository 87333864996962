/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, split, map, includes, size, isEmpty } from 'lodash'

import { messageList, messageUnreadList, readMessageIdsList } from '../actions/message'
import { organisationList } from '../actions/organisation'
import { messageReadList } from '../actions/message_read'
import { userList } from '../actions/user'

import MainLayout from './MainLayout'
import MenuTop from './MenuTop'
import MenuBottom from './MenuBottom'
import Timestamp from './Timestamp'
import DefaultIconButton from './DefaultIconButton'
import DefaultToggleButton from './DefaultToggleButton'
import BusyMask from './BusyMask'
import InfiniteScroll from 'react-infinite-scroller'

import up_light from '../images/up_light.png'
import up_dark from '../images/up_dark.png'
import down_dark from '../images/down_dark.png'
import message_read_icon from '../images/message_read_outline_dark.png'
import message_icon from '../images/message_outline_dark.png'
import loading_icon from '../images/loading.gif'

class Messages extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedMessage: null,
            messageFilter: true,
            unread_items: [],
            items: [],
            unread_has_more: true,
            has_more: true,
            read_message_ids: [],
        }
    }
    
    componentDidMount() {
        const { dispatch, user } = this.props
        dispatch(organisationList.fetchListIfNeeded())
        dispatch(userList.fetchListIfNeeded())
        dispatch(messageList.invalidateList())
        dispatch(messageList.fetchListIfNeeded())
        dispatch(messageReadList.invalidateList())
        dispatch(messageReadList.fetchListIfNeeded())
        if (!isEmpty(user)) {
            dispatch(messageUnreadList.updateListFilter({read: user.id}))
            dispatch(messageUnreadList.fetchListIfNeeded())
        }
        dispatch(readMessageIdsList.fetch()).then(
            (ids) => {
                this.setState({read_message_ids: ids.ids})
            }
        )
        window.document.addEventListener("visibilitychange", this.reloadMessages)
    }

    componentWillUnmount() {
        window.document.removeEventListener("visibilitychange", null)
    }

    componentDidUpdate(prevProps) {
        const { dispatch, user } = this.props
        dispatch(messageList.fetchListIfNeeded())
        dispatch(messageReadList.fetchListIfNeeded())
        if (prevProps.user !== user && !isEmpty(user)) {
            dispatch(messageUnreadList.updateListFilter({read: user.id}))
            dispatch(messageUnreadList.fetchListIfNeeded())
        }
    }

    reloadMessages = () => {
        const { dispatch, user } = this.props
        dispatch(messageList.invalidateList())
        dispatch(messageReadList.invalidateList())
        if (!isEmpty(user)) {
            dispatch(messageUnreadList.invalidateList())
            dispatch(messageUnreadList.fetchListIfNeeded())
        }
    }

    toRegisterPage = () => {
        const { history } = this.props
        history.push('/register')
    }
    
    onExpandMessage = (e, message_id) => {
        const { selectedMessage } = this.state
        e.preventDefault()
        this.setState({
            selectedMessage: message_id === selectedMessage ? null : message_id
        }, () => { this.markAsRead() })
    }

    markAsRead = () => {
        const { dispatch, user } = this.props
        const { selectedMessage, read_message_ids } = this.state
        if (selectedMessage !== null && !includes(read_message_ids, selectedMessage) && !isEmpty(user)) {
            dispatch(messageReadList.saveNewObject({message: selectedMessage}))
            dispatch(messageReadList.invalidateList())
            read_message_ids.push(selectedMessage)
        }
    }

    onToggleFilter = (value) => {
        this.setState({messageFilter:value})
        this.reloadMessages()
    }

    renderMessage = (message, k) => {
        const { is_secondary_light, secondary } = this.props
        const { selectedMessage, read_message_ids } = this.state
        let icon_type = ''
        if (selectedMessage !== message.id) { icon_type = down_dark }
        else { icon_type = is_secondary_light ? up_dark : up_light }
        const body_parts = split(message.body, "\n")

        if ( includes(read_message_ids, message.id) ) {
            message.read_status = "read"
        }
            
        return (
            <div css={ container } key={ k } onClick={ (e) => this.onExpandMessage(e, message.id) }>
              <div css={ time_container }>
                <div css={ css`width:170px;` }>
                  <Timestamp
                      value={ message.created_at }
                      format="dateshort-time"
                  />
                </div>
                <div css={ msg_status_icon }>
                  { message.read_status === "read" && 
                    <img src={ message_read_icon } alt="" />
                  }
                  { message.read_status === "unread" &&
                    <img src={ message_icon } alt="" />
                  }
                </div>
              </div>
              <div css={ selectedMessage !== message.id ? body_container : body_container_expanded }>
                { map(body_parts, function(v, key) {
                      return (<p key={ key } css={ css`width:100%;` }>{ v }</p>)
                  })}
              </div>
              <div css={ line_container }>
                <hr css={ line } />
                <div css={ [button_container,
                            selectedMessage === message.id ?
                            css`background:${secondary};
                                @media (prefers-color-scheme: dark) {
                                  background: ${secondary} !important;
                               }` :
                            css`background:#e5e5e5;` ] }>
                  <DefaultIconButton
                      icon_src={ icon_type }
                      extra_class={ icon }
                      icon_height="35px"
                  />
                </div>
              </div>
            </div>
        )
    }

    loadMoreItems = () => {
        const { dispatch, pagination } = this.props
        if (isEmpty(pagination) || pagination.next_page) {
            this.setState({
                has_more: false,
            })
            dispatch(messageList.updateListFilter({page: pagination.next_page}))
            dispatch(messageList.invalidateList())
            dispatch(messageList.fetchListIfNeeded())
            this.setState({
                has_more: true,
            })
        } else {
            this.setState({
                has_more: false,
            })
        }
    }

    loadMoreUnreadItems = () => {
        const { dispatch, unread_pagination, user } = this.props
        if (isEmpty(unread_pagination) || unread_pagination.next_page) {
            this.setState({
                unread_has_more: false,
            })
            dispatch(messageUnreadList.updateListFilter({page: unread_pagination.next_page, read: user.id}))
            dispatch(messageUnreadList.invalidateList())
            dispatch(messageUnreadList.fetchListIfNeeded())
            this.setState({
                unread_has_more: true,
            })
        } else {
            this.setState({
                unread_has_more: false,
            })
        }
    }
    
    render() {
        const { messages,
                unread_messages,
                history,
                user,
                is_loading,
                is_unread_loading,
                is_busy,
                primary,
        } = this.props
        const { messageFilter,
                items,
                has_more,
                unread_items,
                unread_has_more,
                read_message_ids,
        } = this.state
        const item_ids = map(items, (item) => {
            return item.id
        })
        const unread_item_ids = map(unread_items, (item) => {
            return item.id
        })
        map(messages, (message) => {
            if (!includes(item_ids, message.id)) {
                if (includes(read_message_ids, message.id)) {
                    items.push(Object.assign({}, message, {read_status: 'read'}))
                } else {
                    items.push(Object.assign({}, message, {read_status: 'unread'}))
                }
            }
        })
        map(unread_messages, (message) => {
            if (!includes(unread_item_ids, message.id)) {
                if (includes(read_message_ids, message.id)) {
                    unread_items.push(Object.assign({}, message, {read_status: 'read'}))
                } else {
                    unread_items.push(Object.assign({}, message, {read_status: 'unread'}))
                }
            }
        })
        
        return (
            <MainLayout history={ history }>
              { is_busy && <BusyMask /> }
              <div css={ menu_top }>
                <MenuTop
                    page="Messages"
                    history={ history }
                />  
              </div>
              { !isEmpty(user) ?
                <div css={ messages_container }>
                  <div css={ filter_toggle }>
                    <DefaultToggleButton
                        value={ messageFilter }
                        onChange={ this.onToggleFilter }
                        off_label="Show All"
                        on_label="Unread Only"
                    />
                  </div>
                  { messageFilter ?
                    <div css={ [inbox, !isEmpty(user) ?
                                css`margin-top:120px;@media(min-height: 1025px){margin-top: 180px;}` :
                                css`margin-top:60px;@media(min-height: 1025px){margin-top: 120px;}`] }>
                      { size(unread_messages) === 0 ? <p css={ no_messages }>There are no unread messages</p> :
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={this.loadMoreUnreadItems}
                            threshhold={ 10 }
                            hasMore={ !is_unread_loading && unread_has_more }
                            loader={
                                <div key={0} css={ loader_container }>
                                  <img alt="loading" src={ loading_icon } css={ css`height:50px;`} />
                                </div>
                            }
                            >
                          { unread_items.map((message, k) => this.renderMessage(message, k)) }
                        </InfiniteScroll>
                      }
                    </div>
                    :
                    <div css={ [inbox, !isEmpty(user) ?
                                css`margin-top:120px;@media(min-height: 1025px){margin-top: 180px;}` :
                                css`margin-top:60px;@media(min-height: 1025px){margin-top: 120px;}`] }>
                      { size(messages) === 0 ? <p css={ no_messages }>There are no messages</p> :
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={this.loadMoreItems}
                            threshhold={ 10 }
                            hasMore={ !is_loading && has_more }
                            loader={
                                <div key={0} css={ loader_container }>
                                  <img alt="loading" src={ loading_icon } css={ css`height:50px;`} />
                                </div>
                            }
                            >
                          { items.map((message, k) => this.renderMessage(message, k)) }
                        </InfiniteScroll>
                      }
                    </div>
                  }
                </div>
                :
                <div css={ empty_messages_container }>
                  <p css={ no_messages }>{`In order to receive messages, please register `}
                    <span
                        css={ [fake_link, css`color:${primary};
                        @media (prefers-color-scheme: dark) {
                            color:${primary} !important;
                        }`] }
                        onClick={ this.toRegisterPage }>
                      {`here`}
                    </span>.</p>
                </div>
              }
              <div css={ menu_bottom }>
                <MenuBottom
                    page="Messages"
                />
              </div>
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const user = head(userList.getVisibleObjects()) || {}
    const organisation = head(organisationList.getVisibleObjects()) || {}
    const messages = messageList.getVisibleObjects() || []
    const unread_messages = messageUnreadList.getVisibleObjects() || []
    return {
        messages,
        user,
        secondary: organisation.mobile_secondary_colour_hex,
        primary: organisation.mobile_primary_colour_hex,
        is_secondary_light: organisation.is_mobile_secondary_colour_light,
        unread_messages,
        is_busy: organisationList.isLoading() ||
                 messageList.isLoading() ||
                 messageReadList.isSaving() ||
                 userList.isLoading(),
        pagination: messageList.getPagination(),
        unread_pagination: messageUnreadList.getPagination(),
        is_loading: messageList.isLoading(),
        is_unread_loading: messageList.isLoading(),
    }
}
export default connect(mapStateToProps)(Messages)

const container = css`
display: flex;
flex-direction: column;
align-items: center;
cursor: pointer;
`

const messages_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
`

const empty_messages_container = css`
display: flex;
flex-direction: column;
align-items: center;
margin-top: 60px;
@media(min-height: 1025px) {
    margin-top: 120px;
}
`

const menu_top = css`
width: 100%;
height: 60px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: white;
position: fixed;
left: 0;
top: 0;
z-index: 2;
@media(min-height: 1025px) {
    height: 120px;
}
`

const filter_toggle = css`
width: 100%;
height: 60px;
display: flex;
justify-content: center;
align-items: center;
position: fixed;
left: 0;
top: 60px;
background: white;
cursor: pointer;
z-index: 1;
@media(min-height: 1025px) {
    top: 120px;
}
`

const inbox = css`
flex-grow: 1;
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
background: white;
padding-left: 20px;
padding-right: 20px;
margin-bottom: 60px;
@media(min-height: 1025px) {
    margin-bottom: 120px;
}
`

const time_container = css`
width: 100%;
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
background: white;
font-family: 'Montserrat', sans-serif;
font-size: 12px;
letter-spacing: 2.6px;
font-weight: 600;
text-align: left;
text-transform: uppercase;
color: black;
padding-bottom: 10px;
padding-top: 10px;
`

const body_container = css`
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
background: white;
color: black;
font-size: 16px;
max-height: 77px;
overflow: hidden;
position: relative;
text-align: left;
                     &:after {
                         content: "";
                         text-align: right;
                         position: absolute;
                         bottom: 0;
                         right: 0;
                         width: 100%;
                         height: 30px;
                         background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 70%);
                     }
`

const body_container_expanded = css`
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
background: white;
color: black;
font-size: 16px;
text-align: left;
`

const line_container = css`
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
background: white;
`

const line = css`
width: calc(100% - 45px);
padding: 0;
margin: 0;
`

const menu_bottom = css`
width: 100%;
height: 60px;
display: flex;
align-items: center;
background-color: white;
position: fixed;
left: 0;
bottom: 0;
@media(min-height: 1025px) {
    height: 120px;
}
`
const icon = css`
height: 100%;
display: flex;
justify-content: center;
align-items: center;
`

const button_container = css`
border-radius: 50%;
button:focus {
    outline: none;
}
`

const no_messages = css`
text-align: center;
`

const msg_status_icon = css`
  width: 25px;
  height: 25px;

  img {
    width: 25px;
    height: 25px;
  }

`

const loader_container = css`
display: flex;
width: 100%;
flex-direction: column;
align-items: center;
padding:10px;
`

const fake_link = css`
&:hover{
cursor:pointer;
text-decoration:underline;
}`
