/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { Link } from 'react-router-dom'
import { head } from 'lodash'

import { organisationList } from '../actions/organisation'

class DefaultIconLabelButton extends Component {
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(organisationList.fetchListIfNeeded())
    }
    
    render() {
        const {
            extra_class,
            label,
            onClickButton,
            disabled,
            to,
            unread,
            icon_src,
            color,
            primary,
            is_primary_light,
        } = this.props;
        
        return(
            <Link
                to={to}
                css={[main,extra_class]}
                onClick={!disabled ? onClickButton : function() {}} 
            >
              {icon_src &&
               <div css={ container }>
                 { unread && unread > 0 ?
                                      <div css={ [unread_icon_container,
                                                  css`&:after{background:${primary};
                                                      @media (prefers-color-scheme: dark) {
                                                        background: ${primary} !important;
                                                      }
                                                      color:${is_primary_light ? 'black' : 'white'};}`] }
                                           data-after-content={ unread > 99 ? 99 : unread }>
                                        <img src={icon_src} alt="" css={ icon } />
                                      </div>
                   :
                                      <div css={ icon_container } >
                                        <img src={icon_src} alt="" css={ icon } />
                                        
                                      </div>
                 }
               </div>
              }
              <span css={ [text, css`color:${color};`] }>{label && label}</span>
            </Link>
        )
    }
}

function mapStateToProps(state, props) {
    const organisation = head(organisationList.getVisibleObjects()) || {}
    return {
        is_primary_light: organisation.is_mobile_primary_colour_light,
        primary: organisation.mobile_primary_colour_hex
    }
}

export default connect(mapStateToProps)(DefaultIconLabelButton)

const main = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100%;
width: 100%;
`

const container = css`
display: flex;
justify-content: center;
align-items: center;
height: 30%;
`

const unread_icon_container = css`
position: relative;
height: 100%;
display: flex;
align-items: center;
display: yellow;
&:after {
height: 12px;
width: 12px;
display: flex;
align-items: center;
justify-content: center;
content: attr(data-after-content);
position: absolute;
left: 54%;
bottom: 5%;
background-color: white;
font-family: 'Montserrat', sans-serif;
font-weight: 600;
font-size: 8px;
color: black;
border-radius: 50%;
}
`

const icon_container = css`
height: 100%;
text-align: center;
`

const icon = css`
height: 100%;
width: auto;
object-fit: scale-down;
`

const text = css`
font-family: 'Montserrat', sans-serif;
text-transform: uppercase;
font-weight: 600;
letter-spacing: 1px;
font-size: 10px;
@media(min-height:1025px) {
font-size: 20px;
letter-spacing: 2px;
}
`
