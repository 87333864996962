import { ItemList } from '../orm'
import { ENTITY_KEY__PAYNOW_PAYMENT, LIST_KEY__PAYNOW_PAYMENT } from './ItemListKeyRegistry'

class PayNowPaymentList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__PAYNOW_PAYMENT
    }
}

export const payNowPaymentList = new PayNowPaymentList(LIST_KEY__PAYNOW_PAYMENT)
