/** @jsx jsx */
import react, { Component } from 'react';
import { connect } from "react-redux"
import { jsx, css, Global } from "@emotion/core"
import { map, head, filter, isEmpty } from "lodash"
import moment from "moment"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"

import { eventList } from "../actions/event"
import { organisationList } from "../actions/organisation"

import MainLayout from "./MainLayout"
import MenuTop from "./MenuTop"
import MenuBottom from "./MenuBottom"
import BusyMask from "./BusyMask"

import { default_theme as theme } from "../emotion/theme"

class Events extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dayEvents: [],
      date: moment(),
      calendarComponentRef: react.createRef()
    }
  }
  
  componentDidMount() {
    const { dispatch } = this.props
    dispatch(eventList.fetchListIfNeeded())
    const sessionStorageDate = sessionStorage.getItem("session_storage_date")
    const sessionStorageEvents = JSON.parse(sessionStorage.getItem('session_storage_event'))
    if (sessionStorage.getItem("session_storage_date")) {
        this.setState({date: moment(sessionStorageDate)})
        const calendarApi = this.state.calendarComponentRef.current.getApi()
        calendarApi.gotoDate(moment(sessionStorageDate).format('YYYY-MM-DD'))

        
    }
    if (isEmpty(sessionStorageEvents) === false && sessionStorageDate !== null) {
        this.selectDay(sessionStorageDate, sessionStorageEvents)
    }
  }

  handleEventClick = (event) => {
    const { history } = this.props
    const event_id = event.id
    history.push("/event/" + event_id)
  }
 

  selectDay = (date, events) => {
    this.setState({
      dayEvents: events,
      date: moment(date),
    })
    sessionStorage.setItem("session_storage_date", moment(date))
    sessionStorage.setItem("session_storage_event", JSON.stringify(events))
  };

  getDayEvents = (date, events) => {
    return filter(events, (event) => {
      return moment(event.start).isSame(moment(date), "date")
    })
  }

  renderDayCellContent = (dayInfo, createElementWithValidation) => {
    const { calendar_events, secondary, is_secondary_light } = this.props
    const selectedDate = this.state.date
    const { dayNumberText, date } = dayInfo
    const dayEvents = this.getDayEvents(date, calendar_events)
    const selected = moment(date).isSame(moment(selectedDate), "date")
    const textColour = is_secondary_light ? "black" : "white"

    return (
      <div
        css={calendar_day_cell}
        onClick={(e) => {
          this.selectDay(date, dayEvents)
        }}
      >
        <div css={selected ? [calendar_day_cell_date, 
                             css`background: ${secondary}; color: ${textColour};`,] : calendar_day_cell_date}
        >
        {dayNumberText && dayNumberText}
        </div>
        <div css={calendar_day_cell_events}>
          {map(dayEvents, (event) => {
            const { color, id } = event;
            return (
              <div
                key={id}
                css={[
                  calendar_day_cell_event,
                  css`
                    background: ${color};
                  `,
                ]}
              />
            );
          })}
        </div>
      </div>
    )
  }

  renderDayEvents = () => {
    const { dayEvents } = this.state

    return (
      <div
        css={css`
          width: 100%;
          padding: 1rem;
        `}
      >
        <div>
          {map(dayEvents, (event) => {
            const { id, title, color, start, allDay } = event

            return (
              <div key={id}>
                <div
                  css={event_container}
                  onClick={() => this.handleEventClick(event)}
                >
                  <div css={[event_title, css`border-left 6px solid ${color}`]}>
                    {title}
                  </div>
                  <div css={event_start}>
                    {allDay ? "All Day" : moment(start).format("h:mm A")}
                  </div>
                </div>
                <hr css={event_line} />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    const {
      calendar_events,
      history,
      is_loading,
      secondary,
      is_secondary_light,
    } = this.props;
    const textColour = is_secondary_light ? "black" : "white"
    return (
        <MainLayout history={history}>
          {is_loading && <BusyMask />}
          <div css={menu_top}>
            <MenuTop page="Events" history={history} />
          </div>
          <div css={content}>
            <Global
                styles={[
                    global_styles,
                    css`.fc .fc-toolbar.fc-header-toolbar { background: ${secondary} !important;} .fc-toolbar-title { color: ${textColour} !important;} }`,
                ]}
            />
            {is_loading !== true && (
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    headerToolbar={{
                        left: "prev",
                        center: "title",
                        right: "next",
                    }}
                    height="400px"
                    ref={this.state.calendarComponentRef}
                    dayCellContent={this.renderDayCellContent}
                    events={calendar_events}
                    initialDate={sessionStorage.getItem("session_storage_date") && moment(sessionStorage.getItem("session_storage_date")).format('YYYY-MM-DD')}
                />
            )}
            {this.renderDayEvents()}
            <div css={menu_bottom}>
              <MenuBottom page="Events" />
            </div>
          </div>
        </MainLayout>
    );
}
}
function mapStateToProps() {
  const calendar_events = eventList.getAsCalendarEvents()
  const organisation = head(organisationList.getVisibleObjects()) || {}

  return {
    calendar_events,
    is_loading: eventList.isLoading(),
    secondary: organisation.mobile_secondary_colour_hex,
    primary: organisation.mobile_primary_colour_hex,
    is_secondary_light: organisation.is_mobile_secondary_colour_light,
    is_primary_light: organisation.is_mobile_primary_colour_light,
  };
}
export default connect(mapStateToProps)(Events)

const menu_top = css(
  theme.mq({
    width: "100%",
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    "background-color": "white",
    position: "fixed",
    left: "0",
    top: "0",
    "z-index": "2",
    height: ["120px", "60px"],
  })
);

const content = css(
  theme.mq({
    width: "100%",
    color: "black",
    "margin-top": ["120px", "60px"],
    "margin-bottom": ["120px", "60px"],
  })
);

const menu_bottom = css(
  theme.mq({
    width: "100%",
    display: "flex",
    "align-items": "center",
    "background-color": "white",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: ["120px", "60px"],
  })
);

const global_styles = css`
    .fc {
    font-family: 'Montserrat', sans-serif !important;
    text-transform: uppercase !important;
    font-size: 0.6rem !important;
    }
    .fc .fc-toolbar.fc-header-toolbar {
    height: 60px;
    margin-bottom: 0px;
    
    font-weight: bold;
    

    }
    .fc .fc-daygrid-day.fc-day-today {
    background: #f3f6ff;
    }
    .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    border: 0;
    padding: 5px 0px;
    }
    .fc .fc-toolbar-title {
    color: #fff;
    }
    .fc .fc-button-primary {
    background: #474747 !important;
    border: #474747;
    }
    .fc-h-event .fc-event-main {
    color: #fff;
    }
    .fc-theme-standard .fc-popover {
    position: relative !important;
    top: 100% !important;
    left: 0px !important;
    }
    .fc-event-main {
    display: flex;
    border: none;
    color: #000;
    }

    .fc-scrollgrid-section-body{
    background: #f3f6ff !important;
    }
    .fc-scrollgrid-sync-table {
    overflow: hidden;
    }
    .fc-col-header th {
    border: 0 !important;
    }

    }

    .fc .fc-button-primary {
    background: #444a5a;
    border: #474747;
    border-radius: 50px;
    }
    .fc-prev-button {
    margin-left: 15px !important;
    }
    .fc-next-button {
    margin-right: 15px !important;
    }
    
    .fc-event-main::before {
    content: 'All day:';
    }
    .fc-daygrid-more-link {
    display: flow-root list-item;
    bottom: 40px;
    left: 10px;
    font-size: 0px;
    }

    .fc-daygrid-day-top {
    height: 50px;
    }

    .fc-daygrid-day-number {
    display: flex;
    width: 100%;
    height: 100%;
    }

    .fc-daygrid-day-bottom {
    display: none;
    }

    .fc-daygrid-day-events {
    display: none;
    }
`;
const calendar_day_cell = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin-top: 0.1rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
`;

const calendar_day_cell_date = css`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  padding: 0.5rem;
  border-radius: 25%;
`;

const calendar_day_cell_events = css`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
`;

const calendar_day_cell_event = css`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
`;

const event_container = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2rem;
`;

const event_title = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  padding-left: 10px;
`;

const event_start = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const event_line = css`
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;
