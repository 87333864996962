/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, split, map, has } from 'lodash'

import MainLayout from './MainLayout'
import MenuTop from './MenuTop'
import MenuBottom from './MenuBottom'
import DonateForm from './DonateForm'
import BusyMask from './BusyMask'

import { organisationList } from '../actions/organisation'
import { imageList } from '../actions/image'
import { payNowMerchantList } from '../actions/paynow_merchant'
import { payNowPaymentList } from '../actions/paynow_payment'

import { default_theme as theme } from '../emotion/theme'

class Donate extends Component {

    constructor(props) {
        super(props)
        this.state = {
            display_footer_menu: true,
        }
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(organisationList.fetchListIfNeeded())
        dispatch(imageList.fetchListIfNeeded())
        dispatch(payNowMerchantList.fetchListIfNeeded())
    }

    handleSubmit = (values) => {
        const { dispatch, organisation, paynow_merchant } = this.props
        const that = this
        const on_done = (json) => {
            if (has(json, 'id')) {
                that.postToNetCash(
                    paynow_merchant.service_key,
                    paynow_merchant.vendor_key,
                    json.id,
                    organisation.name,
                    parseFloat(values.amount).toFixed(2)
                )
            }
            else {
                console.log('Transaction could not be created')
            }
        }
        const data = Object.assign({}, {merchant: paynow_merchant.id, amount_rands: values.amount})
        return dispatch(payNowPaymentList.saveNewObject(data, on_done))
    }
    
    toggleMenu = () => {
        const { display_footer_menu } = this.state
        this.setState({
            display_footer_menu: !display_footer_menu,
        })
    }

    postToNetCash = (serviceKey, vendorKey, paymentID, description, amountRands) => {
        document.getElementById('m1').value = serviceKey
        document.getElementById('m2').value = vendorKey
        document.getElementById('p2').value = paymentID
        document.getElementById('p3').value = description
        document.getElementById('p4').value = amountRands
        document.getElementById('payNowForm').submit()
    }

    render() {
        const { organisation, organisation_logo, logo_background, history, is_loading } = this.props
        const { display_footer_menu } = this.state
        const body_parts = split(organisation.bank_details, "\n")
        return (
            <MainLayout history={ history }>
              { is_loading && <BusyMask /> }
              <div css={ menu_top }>
                <MenuTop
                    page="Donate"
                    history={ history }
                />
              </div>
              <div css={ content }>
                <div css={ [logo, css`background:${logo_background};
                  @media (prefers-color-scheme: dark) {
                      background:${logo_background} !important;
                  }`] }>
                  <img src={ organisation_logo } alt="" css={ image } />
                </div>

                <div css={ donate_container }>
                  <label css={ label }>Donate</label>
                  <p css={ paragraph }>
                    We are always in need of funds to deliver on our mission and would welcome any donation.
                  </p>
                  { organisation && organisation.use_paynow &&
                    <p css={ [ paragraph, css`padding:0;`] }><strong>I would like to make a donation of:</strong></p>
                  }
                  { organisation && organisation.use_paynow &&
                    <DonateForm
                        onSubmit={ this.handleSubmit }
                        toggleMenu={ this.toggleMenu }
                    />
                  }
                </div>
                { organisation && organisation.use_paynow &&
                  <div css={ policy_container }>
                    <p css={ note }>
                      Note: All payments are processed through Netcash and then the payment provider you select. Any additional information requested is solely used by the payment provider you select. No payment information is kept by { organisation.name } at any time.
                    </p>
                  </div>
                }
                <div css={ [donate_container, css`padding:10px;`] }>
                  { organisation && organisation.use_paynow ?
                    <p css={ paragraph }>
                      Alternatively, you can donate
                      to { organisation.name } via <strong>EFT</strong>.</p>
                    :
                    <p css={ paragraph }>
                      You can donate
                      to { organisation.name } via <strong>EFT</strong>.</p>
                  }
                  <p css={ [ paragraph, css`padding:0;`] }><strong>Bank Details:</strong></p>
                  { map(body_parts, function(v, k) {
                        return (<span key={ k }>{ v }</span>)
                    })}
                </div>
                { display_footer_menu &&
                  <div css={ menu_bottom }>
                    <MenuBottom
                        page="Messages"
                    />
                  </div>
                }
              </div>
              <form id="payNowForm" action="https://paynow.netcash.co.za/site/paynow.aspx" method="POST">
                <input type="hidden" name="m1" id="m1" value="" />
                <input type="hidden" name="m2" id="m2" value="" />
                <input type="hidden" name="p2" id="p2" value="" />
                <input type="hidden" name="p3" id="p3" value="" />
                <input type="hidden" name="p4" id="p4" value="" />
                <input type="hidden" name="Budget" id="budget" value="Y" />
              </form>
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const organisation = head(organisationList.getVisibleObjects()) || {}
    const paynow_merchant = head(payNowMerchantList.getVisibleObjects()) || {}
    
    return {
        organisation,
        paynow_merchant,
        organisation_logo: organisation.thumbnail,
        logo_background: organisation.mobile_logo_background_colour_hex,
        is_loading: organisationList.isLoading() || imageList.isLoading() || payNowMerchantList.isLoading()
    }
}
export default connect(mapStateToProps)(Donate)

const menu_top = css`
width: 100%;
height: 60px;
display: flex;
justify-content: center;
align-items: center;
background-color: white;
position: fixed;
left: 0;
top: 0;
z-index: 2;
@media(min-height: 1025px) {
height: 120px;
}
`

const content = css`
width: 100%;
color: black;
margin-top: 60px;
margin-bottom: 60px;
@media(min-height: 1025px) {
margin-top: 120px;
margin-bottom: 120px;
}
`

const logo = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 20vh;
`

const image = css`
max-height: 90%;
max-width: 90%;
object-fit: contain;
`

const donate_container = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: ${theme.colors.light};
`

const label = css`
font-size: 20px;
text-align: center;
text-transform: uppercase;
font-weight: 600;
margin-top: 8px;
`

const menu_bottom = css`
width: 100%;
height: 60px;
display: flex;
align-items: center;
background-color: white;
position: fixed;
left: 0;
bottom: 0;
@media(min-height: 1025px) {
height: 120px;
}
`

const paragraph = css`
text-align: center;
width: 80%;
padding-bottom: 0px;
`

const policy_container = css`
display: flex;
justify-content: center; 
width: 100%; 
padding-top: 10px;
padding-bottom: 10px;
`

const note = css`
text-align: center;
width: 80%;
font-size: 0.8rem;
margin: 0;
`
