/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { get, head, isEmpty, remove, map } from 'lodash'
import { UAParser } from "ua-parser-js"
import uuid from 'react-uuid'
import moment from 'moment'

import { eventList } from '../actions/event'
import { eventFollowerList } from '../actions/eventFollower'
import { unregisteredEventFollowerList } from '../actions/unregisteredEventFollower'
import { userList } from '../actions/user'

import MainLayout from './MainLayout'
import MenuTop from './MenuTop'
import MenuBottom from './MenuBottom'
import BusyMask from './BusyMask'
import { default_theme as theme } from '../emotion/theme'
import { organisationList } from '../actions/organisation';

import globe_icon from '../images/globe_outline_dark.png'
import paperclip_icon from '../images/paperclip_outline_dark.png'

class EventDetail extends Component {
    constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    };
  }

    componentDidMount() {
        const { dispatch, event_id } = this.props
        
        dispatch(eventList.invalidateList())
        dispatch(eventList.ensureObjectsLoaded([event_id]))
        dispatch(organisationList.fetchListIfNeeded())
        this.updateEventFollowerList()
      }
      
      componentDidUpdate(prevProps) {
        const { user_id, psuedo_user_uuid } = this.props

        if (!user_id && !psuedo_user_uuid) {
          localStorage.setItem("psuedo_user_uuid", uuid())
        }

        if (prevProps.user_id !== user_id) {
            this.updateEventFollowerList()
        }
    }

    updateEventFollowerList = () => {
        const { dispatch, event_id, user_id } = this.props
        dispatch(eventFollowerList.updateListFilter({event: event_id, follower: user_id}))
        dispatch(eventFollowerList.invalidateList())
        dispatch(eventFollowerList.fetchListIfNeeded())
    }

    updateUnregisteredEventFollowerList = () => {
        const { dispatch, event_id, psuedo_user_uuid } = this.props
        dispatch(unregisteredEventFollowerList.updateListFilter({event: event_id, follower: psuedo_user_uuid}))
    }

    addToCalendar = () => {
      const { event, user_agent } = this.props
      const operating_system = user_agent.getOS().name
      if (operating_system === "iOS") {
          eventList.saveToAppleCal(event)
      }else if (operating_system.includes("Android") === true) {
          eventList.saveToGoogleCal(event)
      }else{
          console.error(`Unknown operating system: ${operating_system}`)
          eventList.saveToGoogleCal(event)
      }
      this.hideModal()
    }

  renderCalendarModal = () => {
    return (
      <div css={overlay_style}>
        <div css={modal_style}>
          <div css={header_container_style}>
            <h3 css={modal_heading_style}>Add to calendar</h3>
          </div>
          <button css={modal_btn_style} onClick={() => this.addToCalendar()}>Yes</button>
          <button css={modal_btn_style} onClick={() => this.hideModal()}>No</button>
        </div>
      </div>
    )
  }

  hideModal = () => {
    this.setState({modalOpen: false})
  }
  showModal = () => {
    this.setState({modalOpen: true})
  }
  
  onAttending = (status) => {
      const { dispatch, user, event_id, eventFollower } = this.props
      const onDone = () => {
          const that = this
          that.updateEventFollowerList()
          if (status !== "rejected") {
              that.showModal()
          }
      }
      if (!isEmpty(eventFollower)) {
          eventFollower.status = status
          return dispatch(eventFollowerList.saveObject(eventFollower, onDone))
      } else {
          const fields = {
              follower: user.id,
              event: event_id,
              status: status
          }
          return dispatch(eventFollowerList.saveNewObject(fields, onDone))
      }
  }

  onUnregisteredFollowerAttending = (status) => {
      const { dispatch, psuedo_user_uuid, event_id } = this.props
      const onDone = () => {
        const that = this
        that.updateUnregisteredEventFollowerList()
        if (status !== "rejected") {
          that.showModal()
        }
      }
      const fields = {
        unregisteredFollower: psuedo_user_uuid,
        event: event_id,
        status: status
      }
      localStorage.setItem(`psuedo_user_attendence_status_for_event_${event_id}`, status)
      return dispatch(unregisteredEventFollowerList.saveNewObject(fields, onDone))
  }

  toRegisterPage = () => {
    const { history } = this.props
    history.push('/register')
  }

  renderAttendanceButtons = () => {
        const { status, user, psuedo_user_attendence_status } = this.props

        let buttons = [
            {
                label: 'Going',
                onClick: 'confirmed',
                style: attendance_btn_style_going,
            },
            {
                label: 'Not Going',
                onClick: 'rejected',
                style: attendance_btn_style_not_going,
            },
            {
                label: 'Maybe',
                onClick: 'maybe',
                style: attendance_btn_style_maybe,
            }
        ]

        if (status) {
            remove(buttons, (button) => { return button.onClick === status})
        }else if (psuedo_user_attendence_status && isEmpty(user)) {
          remove(buttons, (button) => { return button.onClick === psuedo_user_attendence_status})
        }else{
          console.log("No buttons to remove")
        }
        
        return (
            <div css={ attendance_btn_container }>
              { map(buttons, ({label, onClick, style}, key) => {
                    return (
                        <div
                            key={ key }
                            css={[ style, attendance_btn_style ]}
                            onClick={ () => !isEmpty(user) ? 
                              this.onAttending(onClick) : 
                              this.onUnregisteredFollowerAttending(onClick) 
                            }
                        >
                          <span css={css`padding-top:3px;`}>{ label }</span>
                        </div>
                    )
              })
              }
            </div>
        )
  }

    renderEventDateTime = () => {
        const { event } = this.props
        const { event_start, event_end, is_all_day } = event
        
        const date = moment(event_start).format('dddd, DD MMMM YYYY')
        let time = 'All day'
        if (!is_all_day) {
            time = 'from ' + moment(event_start).format('HH:mm') + ' to ' + moment(event_end).format('HH:mm')
        }

        return (
            <div css={ label_style }>
              <div>{ date }</div>
              <div>{ time }</div>
            </div>
        )
    }

  
  
  render() {
      const { event, organisation, user, history, is_loading, primary } = this.props

    return (
        <MainLayout history={history}>
          {is_loading && <BusyMask />}
          <div css={menu_top_style}>
            <MenuTop
                history={history}
            />
          </div>
          <div css={event_container_style}>
            <div css={header_container_style}>
              <div css={
              css`border-left:${event.mobile_event_colour_hex} 10px solid;
                  padding-right: 5px;
                  margin-left:10px;
                  position:relative;
                  left:10px;
                  top:10px;
                  width:100%`
              }
              >
              </div>
              <div css={text_container_style}>
                <label css={[
                    css`border-left: ${event.mobile_event_colour_hex} 0.5rem solid;
                        font-size: 1.2rem;
                        font-weight: 600;
                        line-height: 2rem;
                        padding-left: 1rem;
                    `
                ]}>
                  {event.name}
                </label>
                <label css={[label_style, css`color:${primary}`]}>{event.address}</label>
                {this.renderEventDateTime()}
              </div>
            </div>
            {!is_loading && this.renderAttendanceButtons()}
            {!is_loading &&  isEmpty(user) === true && (
                <p css={unregistered_user_text_style}>In order for us to remind you about this and other events, please register
                  <span
                      css={ [fake_link, css`color:${primary};
                          @media (prefers-color-scheme: dark) {
                          color:${primary} !important;
                          }`] }
                      onClick={ this.toRegisterPage }>
                    {` here`}
                  </span>.</p>
            )}
            <div css={details_container_style}>
              <div css={ details_heading }>Details</div>
              <div css={ details_description }>
                {event.description}
              </div>
            </div>
            {/* {organisation.website && (
                <div css={ additional_content_container }>
                  <hr css={ line_style } />
                  <div css={ additional_content }>
                    <img src={ globe_icon } css={ icon } alt="" />
                    <a css={ [link, css`color:${primary} !important;`] }
                       target={"_blank"}
                       href={ organisation.website }>
                      { organisation.website }
                    </a>
                  </div>
                </div>
            )} */}
            {event.event_document_name && (
                <div css={ additional_content_container }>
                  <hr css={ line_style } />
                  <div css={ additional_content }>
                    <img src={ paperclip_icon } css={ icon } alt="" />
                    <a css={ [link, css`color:${primary} !important;`] }
                       target={"_blank"}
                       href={ event.event_document_url }>
                      { event.event_document_name }
                    </a>
                  </div>
                </div>
            )}
          </div>
          <div css={menu_bottom_style}>
            <MenuBottom
                page="Events"
            />
          </div>
          {this.state.modalOpen && this.renderCalendarModal()}
        </MainLayout>
    )
  }
}

function mapStateToProps(state, props) {
    const event_id = get(props, ["match", "params", "event_id"], null)
    const event = eventList.getObject(event_id) || {}
    const user = head(userList.getVisibleObjects()) || {}
    const user_id = user && user.id
    const user_agent = new UAParser()
    const psuedo_user_uuid = localStorage.getItem("psuedo_user_uuid")
    const psuedo_user_attendence_status = localStorage.getItem(`psuedo_user_attendence_status_for_event_${event_id}`)
    const organisation = head(organisationList.getVisibleObjects()) || {}
    const eventFollower = head(eventFollowerList.getVisibleObjects()) || {}
    const unregisteredEventFollower = head(unregisteredEventFollowerList.getVisibleObjects()) || {}
    
    return {
      user,
      event,
      user_id,
      event_id,
      user_agent,
      organisation,
      eventFollower,
      psuedo_user_uuid,
      unregisteredEventFollower,
      psuedo_user_attendence_status,
      status: eventFollower && eventFollower.status,
      primary: organisation.mobile_primary_colour_hex,
      is_loading: eventList.isLoading() ||
                  organisationList.isLoading() ||
                  eventFollowerList.isLoading()
    }
}

export default connect(mapStateToProps)(EventDetail)

const menu_top_style = css(theme.mq({
  "width": "100%",
  "display": "flex",
  "justify-content": "center",
  "align-items": "center",
  "background-color": "white",
  "position": "fixed",
  "left": "0",
  "top": "0",
  "z-index": "2",
  "height": ["120px", "60px"],
  "background": "white",
  "color": "black"
}))

const event_container_style = css(theme.mq({
  "flex-grow": "1",
  "width": "100%",
  "display": "flex",
  "flex-direction": "column",
  "justify-content": "flex-start",
  "align-items": "center",
  "background": "white",
  "color": "black",
  "margin-top": ["120px", "60px"],
  "margin-bottom": ["120px", "60px"],
}))

const attendance_btn_container = css`
    display: flex;
    width: 100%;
    padding: 0 1rem;
    justify-content: center;
`

const attendance_btn_style = css`
display: flex;
flex: 1;
font-size: 0.8rem;
font-weight: 600;
align-items: center;
justify-content: center;
text-transform: uppercase;
margin: 0.5rem;
padding: 0.7rem 0.5rem;
max-width: 100px;
border-radius: 0.5rem;
`
const attendance_btn_style_going = css`
background: #9bcb3c21;
border: 1px solid #9bcb3c;
`

const attendance_btn_style_maybe = css`
background: #15aed321;
border: 1px solid #15aed3;
`

const attendance_btn_style_not_going = css`
background: #ff000021;
border: 1px solid #ff0000;
`

const header_container_style = css`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
width: 100%;
`
const unregistered_user_text_style = css`
text-align: center;
`
const label_style = css`
    font-size: 1rem;
    padding: 0.2rem 0.5rem;
    line-height: 1.6rem;
`

const fake_link = css`
&:hover{
cursor:pointer;
text-decoration:underline;
}`
const line_style = css`
width: 100%;
margin: 0;
`

const text_container_style = css`
display: flex;
padding: 1rem;
flex-direction: column;
flex: 1;
justify-content: flex-start;
`
const menu_bottom_style = css(theme.mq({
  "width": "100%",
  "display": "flex",
  "align-items": "center",
  "background-color": "white",
  "position": "fixed",
  "left": "0",
  "bottom": "0",
  "height": ["120px", "60px"]
}))

const modal_style = css`
position: absolute;
top: 50%;
left: 50%;
display: block;
padding: 2em;
min-width: 20em;
max-width: 70%;
color: #f00;
background-color: #fff;
border-radius: 1em;
transform: translate(-50%, -50%);
outline: transparent;
text-align: center;
`
const overlay_style = css`
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
background-color: rgba(0, 0, 0, 0.72);
`
const modal_heading_style = css`
color: black;
`
const link = css`
    padding-left: 1rem;
`

const modal_btn_style = css`
text-transform: uppercase;
border: none;
border-radius: 20px;
padding: 10px;
margin: 10px;
font-size: 20px;
font-family: 'Montserrat',sans-serif;
-webkit-letter-spacing: 10;
-moz-letter-spacing: 10;
-ms-letter-spacing: 10;
letter-spacing: 10;
font-weight: 600;
background: #9d43a3;
color: white;
width: 100px;
`

const details_container_style = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 1.5rem;
`

const details_heading = css`
    text-transform: uppercase;
    font-weight: 600;
    padding-bottom: 0.5rem;
`

const details_description = css`
    font-size: 0.9rem;
`

const additional_content_container = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    Padding: 0rem 1.5rem;
`

const additional_content = css`
    display: flex;
    width: 100%;
    padding: 1rem 0;
    align-items: center;
`
const icon = css`
    width: 1.3rem;
    height: 1.3rem;
`
