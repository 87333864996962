import { ItemList } from '../orm'
import { ENTITY_KEY__MESSAGE_READ, LIST_KEY__MESSAGE_READ } from './ItemListKeyRegistry'

class MessageReadList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__MESSAGE_READ
    }
}

export const messageReadList = new MessageReadList(LIST_KEY__MESSAGE_READ)
