/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { includes, isEmpty, map } from 'lodash'

import { articleList } from '../actions/article'
import { imageList } from '../actions/image'

import MainLayout from './MainLayout'
import MenuTop from './MenuTop'
import MenuBottom from './MenuBottom'
import Timestamp from './Timestamp'
import BusyMask from './BusyMask'
import InfiniteScroll from 'react-infinite-scroller'

import placeholder from '../images/placeholder_dark.png'
import loading_icon from '../images/loading.gif'

class News extends Component {

    constructor(props) {
        super(props)
        this.state = {
            items: [],
            has_more: true,
        }
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(articleList.invalidateList())
        dispatch(articleList.clearList())
        dispatch(articleList.fetchListIfNeeded())
        dispatch(imageList.invalidateList())
        dispatch(imageList.fetchListIfNeeded())
    }

    onReadArticle = (e, id) => {
        const { history } = this.props
        e.preventDefault()
        history.push('/article/' + id)
    }

    renderArticle = (article, k) => {
        let article_image = null
        if (article.image_id) {
            article_image = article.thumbnail
        }
        return (
            <div css={ container } key={ k }>
              <div css={ article_container } onClick={ (e) => this.onReadArticle(e, article.id) }>
                <div css={ image_container }>
                  { article_image ?
                    <img src={ article_image } alt="" css={ image } />
                    :
                    <img src={ placeholder } alt="" css={ image_placeholder } />
                  }
                </div>
                <div css={ text_container }>
                  <label css={ label }>{ article.heading }</label>
                  <label css={ date }>
                    { article.todays_message ? <label>Today</label> :
                      <Timestamp
                          value={ article.created_at }
                          format="date-long"
                      />
                    }
                  </label>
                </div>
              </div>
              <hr css={ line } />
            </div>
        )
    }

    loadMoreItems = () => {
        const { dispatch, pagination } = this.props
        if (isEmpty(pagination) || pagination.next_page) {
            this.setState({
                has_more: false,
            })
            dispatch(articleList.updateListFilter({page: pagination.next_page}))
            dispatch(articleList.invalidateList())
            dispatch(articleList.fetchListIfNeeded())
            this.setState({
                has_more: true,
            })
        } else {
            this.setState({
                has_more: false,
            })
        }
    }

    render() {
        const { articles, history, is_loading } = this.props
        const { items, has_more } = this.state
   
        map(articles, (article) => {
            if (!includes(JSON.stringify(items), JSON.stringify(article))) {
                items.push(article)
            }
        })
        return (
            <MainLayout history={ history }>
              { is_loading && <BusyMask /> }
              <div css={ menu_top }>
                <MenuTop
                    page="News"
                    history={ history }
                />
              </div>
              <div css={ news }>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={this.loadMoreItems}
                    threshhold={ 100 }
                    hasMore={ !is_loading && has_more }
                    loader={
                        <div key={0} css={ loader_container }>
                          <img alt="loading" src={ loading_icon } css={ css`height:50px;`} />
                        </div>
                    }
                >
                  { items.map((article, k) => this.renderArticle(article, k)) }
                </InfiniteScroll>
              </div>
              <div css={ menu_bottom }>
                <MenuBottom
                    page="News"
                />
              </div>
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const articles = articleList.getVisibleObjects() || []
    return {
        articles,
        filter: articleList.getFilter(),
        pagination: articleList.getPagination(),
        is_loading: articleList.isLoading(),
    }
}
export default connect(mapStateToProps)(News)

const container = css`
display: flex;
flex-direction: column;
align-items: center;
`

const menu_top = css`
width: 100%;
height: 60px;
display: flex;
justify-content: center;
align-items: center;
background-color: white;
position: fixed;
left: 0;
top: 0;
z-index: 2;
@media(min-height: 1025px) {
    height: 120px;
}
`

const news = css`
flex-grow: 1;
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
background: white;
margin-top: 60px;
margin-bottom: 60px;
@media(min-height: 1025px) {
    margin-top: 120px;
    margin-bottom: 120px;
}
`

const article_container = css`
height: 150px;
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: center;
background: white;
`

const image_container = css`
height: 85%;
width: 40%;
display: flex;
justify-content: center;
align-items: center;
overflow: hidden;
background: white;
`

const image = css`
max-height: 100%;
max-width: 100%;
width: auto;
height: auto;
object-fit: contain;
`

const image_placeholder = css`
height: 70%;
wight: 70%;
`

const text_container = css`
height: 100%;
width: 50%;
display: flex;
flex-direction: column;
justify-content: space-around;
`

const label = css`
font-size: 18px;
text-align: left;
line-height: 22px;
padding-top: 10px;
height: 76px;
overflow: hidden;
width: 95%;
`

const date = css`
font-family: 'Montserrat', sans-serif;
font-size: 12px;
letter-spacing: 2.6px;
font-weight: 600;
text-align: left;
text-transform: uppercase;
color: #7c7c7c;
padding-bottom: 10px;
`

const line = css`
width: 95%;
padding: 0;
margin: 0;
`

const menu_bottom = css`
width: 100%;
height: 60px;
display: flex;
align-items: center;
background-color: white;
position: fixed;
left: 0;
bottom: 0;
@media(min-height: 1025px) {
    height: 120px;
}
`

const loader_container = css`
display: flex;
width: 100%;
flex-direction: column;
align-items: center;
padding:10px;
`
