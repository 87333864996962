import firebase from 'firebase'
import { SubmissionError } from 'redux-form'
import * as http from '../lib/http'
import { has } from 'lodash'
import { ItemList } from '../orm'
import { ENTITY_KEY__TOKEN, LIST_KEY__TOKEN } from './ItemListKeyRegistry'

class TokenList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__TOKEN
    }
}

export const tokenList = new TokenList(LIST_KEY__TOKEN)

export async function requestPermissionIfNeeded() {
    initializeFirebaseIfNeeded()
    return Notification.requestPermission().then((permission) => {
        return permission
    }).catch((e) => {
        console.log(e)
    })
}

export async function getToken() {
    initializeFirebaseIfNeeded()
    if (isBrowserSupported()) {
        const messaging = firebase.messaging()
        messaging.usePublicVapidKey("BDe_EqYgkflHQA9Iran-DQalXJvi7pwqlOURMx68gUnwVWLZABkLZ9JJYyUkuhV75vEU_SvQ6tJnRLKI4xsNJAE")
        return messaging.getToken().then((token) => {
            return token
        }).catch((e) => {
            console.log(e)
        })
    } else {
        console.log('Browser Not Supported')
    }
}


export function isBrowserSupported() {
    return firebase.messaging.isSupported()
}

export function initializeFirebaseIfNeeded() {
    const firebaseConfig = {
        apiKey: "AIzaSyBEss-UV3TRMqHtNjM4fslgi5Oe8gN7QhI",
        authDomain: "npoconnect-1a8f2.firebaseapp.com",
        databaseURL: "https://npoconnect-1a8f2.firebaseio.com",
        projectId: "npoconnect-1a8f2",
        storageBucket: "",
        messagingSenderId: "533646429094",
        appId: "1:533646429094:web:17fddced3066d30ee69d67"
    }
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig)
    }
}

export const ANNOUNCE_SAVING_TOKEN = "ANNONUCE_SAVING_TOKEN"
export const ANNOUNCE_TOKEN_SAVED = "ANNOUNCE_TOKEN_SAVED"
export const ANNOUNCE_TOKEN_NOT_SAVED = "ANNOUNCE_TOKEN_NOT_SAVED"

export function announceSavingToken() {
    return {
        type: ANNOUNCE_SAVING_TOKEN
    }
}

export function announceTokenSaved() {
    return {
        type: ANNOUNCE_TOKEN_SAVED
    }
}

export function announceTokenNotSaved() {
    return {
        type: ANNOUNCE_TOKEN_NOT_SAVED
    }
}

export function saveToken(values) {
    return async (dispatch, getState) => { 
        try {  
            dispatch(announceSavingToken())
            let [json, success] = await http.post(getState(), 'token', {firebase_registration_id: values})
            if (success.status === 200 && has(json, 'firebase_registration_id')) {
                dispatch(announceTokenSaved())
            } else {
                dispatch(announceTokenNotSaved())
                throw new SubmissionError({_error: "Failed to save token"})
            }
        } catch (e) {
            if (e instanceof SubmissionError) {
                throw e
            } else {
                throw new SubmissionError({_error: 'Token failed ' + e.message})
            }
        }
    }
}
