export const LIST_KEY__ORGANISATION = 'organisation'
export const LIST_KEY__ARTICLE = 'article'
export const LIST_KEY__EVENT = 'event'
export const LIST_KEY__EVENT_FOLLOWER = 'event_follower'
export const LIST_KEY__UNREGISTERED_EVENT_FOLLOWER = 'unregistered_event_follower'
export const LIST_KEY__IMAGE = 'fileinfo'
export const LIST_KEY__USER = 'user'
export const LIST_KEY__TOKEN = 'token'
export const LIST_KEY__MESSAGE = 'message'
export const LIST_KEY__MESSAGE_READ = 'message_read'
export const LIST_KEY__PAYNOW_MERCHANT = 'paynow_merchant'
export const LIST_KEY__PAYNOW_PAYMENT = 'paynow_payment'
export const ENTITY_KEY__ORGANISATION = 'organisation'
export const ENTITY_KEY__ARTICLE = 'article'
export const ENTITY_KEY__EVENT = 'event'
export const ENTITY_KEY__EVENT_FOLLOWER = 'event_follower'
export const ENTITY_KEY__UNREGISTERED_EVENT_FOLLOWER = 'unregistered_event_follower'
export const ENTITY_KEY__IMAGE = 'fileinfo'
export const ENTITY_KEY__USER = 'user'
export const ENTITY_KEY__TOKEN = 'token'
export const ENTITY_KEY__MESSAGE = 'message'
export const ENTITY_KEY__MESSAGE_READ = 'message_read'
export const ENTITY_KEY__PAYNOW_MERCHANT = 'paynow_merchant'
export const ENTITY_KEY__PAYNOW_PAYMENT = 'paynow_payment'