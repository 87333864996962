/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, isEmpty, has } from 'lodash'
import { detect } from 'detect-browser'
import { getFormValues } from 'redux-form'

import MainLayout from './MainLayout'
import MenuTop from './MenuTop'
import MenuBottom from './MenuBottom'
import RegisterForm from './RegisterForm'
import BusyMask from './BusyMask'

import { organisationList } from '../actions/organisation'
import { imageList } from '../actions/image'
import { otpTextMessageList, userList } from '../actions/user'
import { register } from '../actions/auth'

import { default_theme as theme } from '../emotion/theme'

class Register extends Component {

    constructor(props) {
        super(props)
        this.state = {
            display_footer_menu: true,
        }
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(organisationList.fetchListIfNeeded())
        dispatch(imageList.fetchListIfNeeded())
    }

    handleSubmit = (values) => {
        const { dispatch, history, organisation, user, form_values } = this.props
        const browser = detect()
        if (!values.id) {
            values.isNewInstance = true
            values.organisation_id = organisation.id
        }
        if (browser && browser.name === 'ios') {
            values.is_apple = true
        } else {
            values.is_apple = false
        }
        const on_done = function(json) {
            const on_done = function() {
                const prevPage = isEmpty(user) ? "Register" : "Profile"
                history.push({
                    pathname: '/complete_registration/' + json.user.id,
                    state: {
                        email: json.user.email,
                        phone_number: json.user.phone_number,
                        prevPage:  prevPage,
                        form_values: form_values,
                    }
                })
            }
            if (has(json, 'user')) {
                dispatch(otpTextMessageList.send(json.user.id, on_done))
            }
        }

        return dispatch(register(values, on_done))
    }
    
    toggleMenu = () => {
        const { display_footer_menu } = this.state
        this.setState({
            display_footer_menu: !display_footer_menu,
        })
    }

    render() {
        const {
            organisation,
            organisation_logo,
            logo_background,
            history,
            user,
            is_loading,
            prev_form_values
        } = this.props
        const { display_footer_menu } = this.state
        const privacy_policy_url = window.LOCAL_SETTINGS.API_BASE.split("api/v1")[0]
                                 + 'static/NPOconnect-App-Privacy-Policy.pdf'
        return (
            <MainLayout history={ history }>
              { is_loading && <BusyMask /> }
              <div css={ menu_top }>
                <MenuTop
                    page={ isEmpty(user) ? "Register" : "Profile"}
                    history={ history }
                />
              </div>
              <div css={ content }>
                <div css={ [logo, css`background:${logo_background};
                  @media (prefers-color-scheme: dark) {
                      background:${logo_background} !important;
                  }`] }>
                  <img src={ organisation_logo } alt="" css={ image } />
                </div>
                <div css={ register_container }>
                  <label css={ label }>{ !isEmpty(user) ? "Update your Details" : "Register for Updates" }</label>
                  <p css={ paragraph }>We'll send updates and news
                    from { organisation.name } directly
                    to your app's inbox. You can disable this at any time.</p>
                  <RegisterForm
                      onSubmit={ this.handleSubmit }
                      toggleMenu={ this.toggleMenu }
                      prev_form_values={ prev_form_values }
                  />
                </div>
                <div css={ policy_container }>
                  <a href={privacy_policy_url}
                     target="_blank"
                     rel="noopener noreferrer"
                  >Privacy Policy</a>
                </div>
                { display_footer_menu &&
                  <div css={ menu_bottom }>
                    <MenuBottom
                        page="Messages"
                    />
                  </div>
                }
              </div>
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const organisation = head(organisationList.getVisibleObjects()) || {}
    const user = head(userList.getVisibleObjects()) || {}
    const prev_form_values = props && props.location && props.location.state && props.location.state.prev_form_values
    return {
        organisation,
        user,
        organisation_logo: organisation.thumbnail,
        logo_background: organisation.mobile_logo_background_colour_hex,
        is_loading: organisationList.isLoading() ||
                    imageList.isLoading() ||
                    userList.isLoading() ||
                    otpTextMessageList.isLoading() ||
                    organisationList.getIsSavingObject() ||
                    imageList.getIsSavingObject() ||
                    userList.getIsSavingObject() ||
                    otpTextMessageList.getIsSavingObject(),
        form_values: getFormValues('REGISTER_FORM')(state),
        prev_form_values,
    }
}
export default connect(mapStateToProps)(Register)

const menu_top = css`
width: 100%;
height: 60px;
display: flex;
justify-content: center;
align-items: center;
background-color: white;
position: fixed;
left: 0;
top: 0;
z-index: 2;
@media(min-height: 1025px) {
height: 120px;
}
`

const content = css`
width: 100%;
color: black;
margin-top: 60px;
margin-bottom: 60px;
@media(min-height: 1025px) {
margin-top: 120px;
margin-bottom: 120px;
}
`

const logo = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 20vh;
`

const image = css`
max-height: 90%;
max-width: 90%;
object-fit: contain;
`

const register_container = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: ${theme.colors.light};
`

const label = css`
font-size: 20px;
text-align: center;
text-transform: uppercase;
font-weight: 600;
padding-top: 30px;
padding-bottom: 10px;
`

const menu_bottom = css`
width: 100%;
height: 60px;
display: flex;
align-items: center;
background-color: white;
position: fixed;
left: 0;
bottom: 0;
@media(min-height: 1025px) {
height: 120px;
}
`

const paragraph = css`
text-align: center;
width: 80%;
padding-bottom: 10px;
`

const policy_container = css`
display: flex;
justify-content: center; 
width: 100%; 
padding: 25px;`
