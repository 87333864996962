/** @jsx jsx */
import { Component } from "react"
import { connect } from "react-redux"
import { jsx, css } from "@emotion/core"
import { head } from "lodash"
import cookie from "react-cookies"

import { imageList } from "../actions/image"
import { organisationList } from "../actions/organisation"

import BusyMask from "./BusyMask"
import MainLayout from "./MainLayout"

import clubconnect_logo_light from "../images/logo-club-connect-light.png"
import clubconnect_logo_dark from "../images/logo-club-connect-dark.png"
import npoconnect_logo_light from "../images/logo-npo-connect-light.png"
import npoconnect_logo_dark from "../images/logo-npo-connect-dark.png"
import faithconnect_logo_light from "../images/logo-faith-connect-light.png"
import faithconnect_logo_dark from "../images/logo-faith-connect-dark.png"
import profconnect_logo_light from "../images/logo-prof-connect-light.png"
import profconnect_logo_dark from "../images/logo-prof-connect-dark.png"

class Splash extends Component {
  componentDidMount() {
    const { dispatch, user_mode } = this.props
    dispatch(organisationList.fetchListIfNeeded())
    dispatch(imageList.fetchListIfNeeded())
    if (user_mode === "web") {
      cookie.save("user_mode", "web", { path: "/" })
    }
    if (user_mode === "app") {
      cookie.save("user_mode", "app", { path: "/" })
    }
    setTimeout(function() {
        window.location.href =
            window.location.href.substring(0, window.location.href.lastIndexOf('/') + 1) + 'landing'
    }, 4000)
  }

  renderSplashLogo = () => {
    const { splash_logo, is_primary_light } = this.props
    
    if (splash_logo === "clubconnect") {
      return (
        <img
          css={icon}
          alt={`${splash_logo} logo`}
          src={is_primary_light ? clubconnect_logo_dark : clubconnect_logo_light}
        />
      )
    } else if (splash_logo === "faithconnect") {
      return (
        <img
          css={icon}
          alt={`${splash_logo} logo`}
          src={is_primary_light ? faithconnect_logo_dark : faithconnect_logo_light}
        />
      )
    } else if (splash_logo === "profconnect") {
      return (
        <img
          css={icon}
          alt={`${splash_logo} logo`}
          src={is_primary_light ? profconnect_logo_dark : profconnect_logo_light}
        />
      )
    } else if (splash_logo === "npoconnect") {
      return (
        <img
          css={icon}
          alt={`${splash_logo} logo`}
          src={is_primary_light ? npoconnect_logo_dark : npoconnect_logo_light}
        />
      )
    } else {
      console.error(`Unknown logo ${splash_logo}`)
      return null
    }
  }

  render() {
    const {
      primary,
      history,
      is_loading,
      logo_background,
      organisation_logo,
      is_primary_light,
    } = this.props
    return (
      <MainLayout history={history}>
        {is_loading && <BusyMask />}
        {organisation_logo && (
          <div
            css={[
              content,
              css`background:${logo_background};
                      &:after{
                        border-top:5vh solid ${logo_background};
                        @media (prefers-color-scheme: dark) {
                          border-top:5vh solid ${logo_background}!important;
                        }
                      }
                      @media (prefers-color-scheme: dark) {
                        background:${logo_background} !important;
                      }`,
            ]}
          >
            <div css={logo}>
              <img src={organisation_logo} alt="" css={image} />
            </div>
          </div>
        )}
        {organisation_logo && (
          <div
            css={[
              buffer,
              css`
                background: ${logo_background};
                @media (prefers-color-scheme: dark) {
                  background: ${logo_background} !important;
                }
              `,
            ]}
          />
        )}
        {organisation_logo && (
          <div
            css={[
              footer,
              css`
                background: ${primary};
                :after {
                  border-bottom: 5vh solid ${primary};
                  @media (prefers-color-scheme: dark) {
                    border-bottom: 5vh solid ${primary} !important;
                  }
                }
                @media (prefers-color-scheme: dark) {
                  background: ${primary} !important;
                }
              `,
            ]}
          >
            <label css={[label, css`color: ${is_primary_light ? "black" : "white"}`]}>
              POWERED BY
            </label>
            {this.renderSplashLogo()}
          </div>
        )}
      </MainLayout>
    )
  }
}
function mapStateToProps(state, props) {
  const organisation = head(organisationList.getVisibleObjects()) || {}
  const user_mode = new URLSearchParams(window.location.search).get("user_mode") || "web"

  return {
    organisation,
    user_mode: user_mode,
    organisation_logo: organisation.thumbnail,
    primary: organisation.mobile_primary_colour_hex,
    splash_logo: organisation.npoconnect_logo_option,
    is_primary_light: organisation.is_mobile_primary_colour_light,
    logo_background: organisation.mobile_logo_background_colour_hex,
    is_loading: organisationList.isLoading() || imageList.isLoading(),
  }
}
export default connect(mapStateToProps)(Splash)

const content = css`
  width: 100%;
  height: 43vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const logo = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const image = css`
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
`

const buffer = css`
  width: 100%;
  height: 32vh;
`

const footer = css`
  width: 100%;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -1px;
  align-items: center;
  :after {
    border-right: 100vw solid transparent;
    @media (min-width: 1024px) {
      border-right: 1024px solid transparent;
    }
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    top: 70vh;
  }
`

const label = css`
  font-size: 3vw;
  letter-spacing: 1vw;
  font-weight: 700;
  @media (min-width: 500px) {
    font-size: 16px;
  }
`

const icon = css`
  width: 40%;
  max-width: 250px;
  padding-bottom: 5vh;
  position: relative;
  top: 10px;
  right: 10px;
`
