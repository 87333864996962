/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css, Global } from '@emotion/core'
import firebase from 'firebase'
import { head, capitalize } from 'lodash'
import { detect } from 'detect-browser'
import cookie from 'react-cookies'

import Prompt from './Prompt'

import { organisationList } from '../actions/organisation'
import { messageList } from '../actions/message'
import { initializeFirebaseIfNeeded, isBrowserSupported } from '../actions/token'

import close_icon_dark from '../images/close_dark.png'
import close_icon_light from '../images/close_light.png'
import share_light from '../images/share_light.png'
import share_dark from '../images/share_dark.png'
import browser_menu_dark from '../images/browser_menu_dark.png'
import browser_menu_light from '../images/browser_menu_light.png'
import a2hs_dark from '../images/a2hs_dark.png'
import a2hs_light from '../images/a2hs_light.png'
import samsung_light from '../images/samsung_light.png'
import samsung_dark from '../images/samsung_dark.png'

class MainLayout extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            deferredPrompt: null,
            display_chrome_install: false,
            display_safari_install: false,
            display_firefox_install: false,
            display_opera_install: false,
            display_samsung_install: false,
        }
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(organisationList.fetchListIfNeeded())
        this.checkForMessages()
        this.registerServiceWorker()
    }

    componentDidUpdate(prevProps) {
        const { dispatch } = this.props
        dispatch(messageList.fetchListIfNeeded())
    }

    checkForMessages = () => {
        const { dispatch } = this.props
        initializeFirebaseIfNeeded()
        if (isBrowserSupported()){
            const messaging = firebase.messaging()
            messaging.onMessage((res) => {
                console.log('Notification Received')
                dispatch(messageList.invalidateList())
                this.addNotification()
                setTimeout(() => { this.removeNotification() }, 8000)
            });
        }
    }

    registerServiceWorker = () => {
        if (window.location.pathname !== '/splash' && window.location.pathname !== '/') {
            if (!document.querySelector('#manifest-root').hasAttribute('href')) {
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker.register('./firebase-messaging-sw.js')
                             .then(function(registration) {
                                 console.log('SW registration successful. Scope: ' + registration.scope)
                             }).then(() => {
                                 this.addDynamicManifest()
                             }).then(() => {
                                 if (!this.isStandalone() && window.location.pathname === '/landing') {
                                     const browser = detect()
                                     let pwaInstalled = cookie.load('pwaInstalled')
                                     if (pwaInstalled === undefined) {
                                         pwaInstalled = 3
                                         cookie.save('pwaInstalled', pwaInstalled, { path: '/' })
                                     }
                                     if (browser && parseInt(pwaInstalled) > 0) {
                                         console.log(capitalize(browser.name) + ' detected')
                                         pwaInstalled = pwaInstalled - 1
                                         cookie.save('pwaInstalled', pwaInstalled, { path: '/' })
                                         switch (browser && browser.name) {
                                             case 'ios' || 'safari':
                                                 this.toggleSafariInstall()
                                                 break
                                             case 'opera':
                                                 this.toggleOperaInstall()
                                                 break
                                             case 'samsung':
                                                 this.toggleSamsungInstall()
                                                 break
                                             default:
                                                 this.installPrompt()
                                         }
                                     }
                                 } else {
                                     console.log('Standalone mode detected')
                                 }
                             })
                             .catch(function(error) {
                                 console.log('Progressive Web Application setup error: ' + error)
                             })
                } else {
                    console.log('Browser does not support Service Workers')
                }
            }    
        }
    }

    addDynamicManifest = () => {
        console.log('Adding dynamic manifest file')
        document.querySelector('#manifest-root').setAttribute('href', window.LOCAL_SETTINGS.API_BASE + 'manifest')
        console.log('Manifest added')
    }

    isStandalone = () => window.matchMedia('(display-mode: standalone)').matches || (('standalone' in window.navigator) && (window.navigator.standalone)) || (window.innerHeight / window.screen.height) > 0.9 || cookie.load('user_mode') === 'app'

    toggleSafariInstall = () => {
        const { display_safari_install } = this.state
        this.setState({
            display_safari_install: !display_safari_install,
        })
    }

    toggleOperaInstall = () => {
        const { display_opera_install } = this.state
        this.setState({
            display_opera_install: !display_opera_install,
        })
    }

    toggleFirefoxInstall = () => {
        const { display_firefox_install } = this.state
        this.setState({
            display_firefox_install: !display_firefox_install
        })
    }

    toggleSamsungInstall = () => {
        const { display_samsung_install } = this.state
        this.setState({
            display_samsung_install: !display_samsung_install
        })
    }

    addNotification = () => {
        const notification = document.getElementById('notification')
        notification.classList.add('is-notification-open')
    }

    removeNotification = () => {
        const notification = document.getElementById('notification')
        notification.classList.remove('is-notification-open')
    }

    toggleChromeInstall = () => {
        const { display_chrome_install } = this.state
        this.setState({display_chrome_install: !display_chrome_install})
    }

    renderSafariInstall = () => {
        const { is_primary_light, primary, organisation } = this.props
        return (
            <Prompt
                close_icon={ is_primary_light ? close_icon_dark : close_icon_light }
                close_background={ primary }
                onClose={ this.toggleSafariInstall }
            >
              <div css={ prompt_header }>
                Install
                <br />
                {organisation.name}
              </div>
              <div css={ prompt_body }>
                Install this application on your home screen for quick and easy access when you're on the go.
              </div>
              <div
                  css={ [
                      prompt_footer,
                      css`background:${primary};
                          color:${ is_primary_light ? 'black' : 'white'};
                          @media (prefers-color-scheme: dark) {
                            background: ${primary} !important;
                        }`
                  ] }
                  onClick={ this.toggleSafariInstall }
              >
                Just tap <img src={ is_primary_light ? share_dark : share_light } alt="" css={ share_icon } /> then 'Add to Home Screen'
              </div>
            </Prompt>
        )
    }

    renderOperaInstall = () => {
        const { is_primary_light, primary, organisation } = this.props
        return (
            <Prompt
                close_icon={ is_primary_light ? close_icon_dark : close_icon_light }
                close_background={ primary }
                onClose={ this.toggleOperaInstall }
            >
              <div css={ prompt_header }>
                Install
                <br />
                {organisation.name}
              </div>
              <div css={ prompt_body }>
                Install this application on your home screen for quick and easy access when you're on the go.
              </div>
              <div
                  css={ [
                      prompt_footer,
                      css`background:${primary};
                          color:${ is_primary_light ? 'black' : 'white'};
                          @media (prefers-color-scheme: dark) {
                            background: ${primary} !important;
                        }`
                  ] }
                  onClick={ this.toggleOperaInstall }
              >
                Just tap <img src={ is_primary_light ? browser_menu_dark : browser_menu_light } alt="" css={ menu_icon } /> then 'Home Screen'
              </div>
            </Prompt>
        )
    }

    renderFirefoxInstall = () => {
        const { is_primary_light, primary, organisation } = this.props
        return (
            <Prompt
                close_icon={ is_primary_light ? close_icon_dark : close_icon_light }
                close_background={ primary }
                onClose={ this.toggleFirefoxInstall }
            >
              <div css={ prompt_header }>
                Install
                <br />
                {organisation.name}
              </div>
              <div css={ prompt_body }>
                Install this application on your home screen for quick and easy access when you're on the go.
              </div>
              <div
                  css={ [
                      prompt_footer,
                      css`background:${primary};
                          color:${ is_primary_light ? 'black' : 'white'};
                          @media (prefers-color-scheme: dark) {
                            background: ${primary} !important;
                        }`
                  ] }
                  onClick={ this.toggleFirefoxInstall }
              >
                Just tap <img src={ is_primary_light ? a2hs_dark : a2hs_light } alt="" css={ menu_icon } /> and follow the prompt
              </div>
            </Prompt>
        )
    }

    renderSamsungInstall = () => {
        const { is_primary_light, primary, organisation } = this.props
        return (
            <Prompt
                close_icon={ is_primary_light ? close_icon_dark : close_icon_light }
                close_background={ primary }
                onClose={ this.toggleSamsungInstall }
            >
              <div css={ prompt_header }>
                Install
                <br />
                {organisation.name}
              </div>
              <div css={ prompt_body }>
                Install this application on your home screen for quick and easy access when you're on the go.
              </div>
              <div
                  css={ [
                      prompt_footer,
                      css`background:${primary};
                          color:${ is_primary_light ? 'black' : 'white'};
                          @media (prefers-color-scheme: dark) {
                            background: ${primary} !important;
                        }`
                  ] }
                  onClick={ this.toggleSamsungInstall }
              >
                Just tap <img src={ is_primary_light ? samsung_dark : samsung_light } alt="" css={ menu_icon } /> and follow the prompt
              </div>
            </Prompt>
        )
    }
    
    installPrompt = () => {
        console.log('Listening for install prompt')
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault()
            console.log('Install prompt fired')
            this.setState({deferredPrompt: e}, () => { this.toggleChromeInstall() })
        })
    }

    showPrompt = () => {
        const { deferredPrompt } = this.state
        this.toggleChromeInstall()
        deferredPrompt.prompt()
        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted' ) {
                console.log('User accepted install')
                cookie.save('pwaInstalled', 0, { path: '/' })
                this.closePrompt()
            } else {
                console.log('User rejected install')
            }
            this.setState({
                deferredPrompt: null,
            })
        })
    }

    closePrompt = () => {
        this.toggleChromeInstall()
        this.setState({
            deferredPrompt: null,
        })
    }

    renderChromeInstall = () => {
        const { is_primary_light, primary, organisation } = this.props
        return (
            <Prompt
                close_icon={ is_primary_light ? close_icon_dark : close_icon_light }
                close_background={ primary }
                onClose={ this.closePrompt }
            >
              <div css={ prompt_header }>
                Install
                <br />
                {organisation.name}
              </div>
              <div css={ prompt_body }>
                Install this application on your home screen for quick and easy access when you're on the go.
              </div>
              <div
                  css={ [
                      prompt_footer,
                      css`background:${primary};
                          color:${ is_primary_light ? 'black' : 'white'};
                          @media (prefers-color-scheme: dark) {
                            background: ${primary} !important;
                        }`
                  ] }
                  onClick={ this.showPrompt }
              >
                Just tap here and follow the prompt
              </div>
            </Prompt>
        )
    }

    renderNotification = () => {
        const { secondary, is_secondary_light } = this.props
        return (
            <div css={ [notification,
                        css`background:${secondary};
                            @media (prefers-color-scheme: dark) {
                              background: ${secondary} !important;
                            }
                            color:${is_secondary_light ? 'black' : 'white'};
                            border 1px solid ${is_secondary_light ? 'black' : 'white'}
                        `] }
                 id="notification"
                 onClick={ () => this.navToMessages() }
                 className="notification"
            >
              You Have 1 New Message
            </div>
        )
    }

    navToMessages = () => {
        const { history } = this.props
        if (window.location.pathname === '/messages') {
            this.toggleNotification()
        } else {
            history.push('/messages')
        }
    }
    
    render() {
        const { children } = this.props
        const {
            display_chrome_install,
            display_safari_install,
            display_firefox_install,
            display_opera_install,
            display_samsung_install,
        } = this.state
        return (
            <div css={ main }>
              <Global styles={ global } />
              <div css={ body } >
                { children }
              </div>
              { display_chrome_install && this.renderChromeInstall() }
              { display_safari_install && this.renderSafariInstall() }
              { display_opera_install && this.renderOperaInstall() }
              { display_firefox_install && this.renderFirefoxInstall() }
              { display_samsung_install && this.renderSamsungInstall() }
              { this.renderNotification() }
            </div>
        )
    }
}
function mapStateToProps(state, props) {
    const { children } = props
    const organisation = head(organisationList.getVisibleObjects()) || {}
    
    return {
        children,
        secondary: organisation.mobile_secondary_colour_hex,
        is_secondary_light: organisation.is_mobile_secondary_colour_light,
        primary: organisation.mobile_primary_colour_hex,
        is_primary_light: organisation.is_mobile_primary_colour_light,
        organisation,
        is_loading: organisationList.isLoading()
    }
}
export default connect(mapStateToProps)(MainLayout)

const global = css`
.notification {
top:-60px;
transition: all 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
@media(min-height: 1025px) {
top:-120px;
}
}
.is-notification-open {
top:0;
transition: all 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
}
`

const main = css`
min-height: 100vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

const body = css`
width: 100%;
height: 100%;
min-height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
max-width: 1024px;
`

const notification = css`
cursor: pointer;
position:fixed;
z-index:999;
left:0px;
width:100%;
height:60px;
display:flex;
justify-content:center;
align-items:center;
transition: all 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
font-family: 'Montserrat', sans-serif;
text-transform: uppercase;
font-weight: 600;
letter-spacing: 2.8px;
font-size: 14px;
@media(min-height: 1025px) {
height:120px;
font-size: 28px;
letter-spacing: 5.2px;
}
`

const prompt_header = css`
text-align: center;
font-family: 'Montserrat', sans-serif;
letter-spacing: 2.8px;
font-size: 20px;
font-weight: 600;
padding: 0 5% 5% 5%;
text-transform: uppercase;
`

const prompt_body = css`
text-align: center;
font-family: 'Montserrat', sans-serif;
letter-spacing: 2.8px;
font-size: 14px;
padding: 0 10% 5% 10%;
`

const prompt_footer = css`
width: 100%;
padding: 5%;
text-align: center;
font-family: 'Montserrat', sans-serif;
font-size: 12px;
border-bottom-right-radius: 5px;
border-bottom-left-radius: 5px;
cursor: pointer;
letter-spacing: 0.8px;
@media(max-width:320px) {
letter-spacing: 0;
}
`
const share_icon = css`
height: 25px;
padding:0 5px;
@media(max-width:320px) {
height: 20px;
}
`

const menu_icon = css`
height: 25px;
@media(max-width:320px) {
height: 20px;
}
`
