/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'

import FormError from './FormError'

class InputArea extends Component {
    
    render() {
        const {
            extra_class,
            placeholder,
            input,
            label,
            tabIndex,
            rows,
            meta,
            ...extraProps
        } = this.props
        return (
            <div>
              <textarea {...input}
                        {...extraProps}
                        placeholder={placeholder}
                        css={[extra_class, main, meta.error && meta.touched && !meta.active && error]}
                        rows={rows}
                        className="form-control"
              />
              { meta.error && meta.touched && !meta.active &&
                <FormError meta={ meta } />
              }
            </div>
        )
    }
}
export default InputArea

const main = css`
border: none;
border-radius: 20px;
padding: 12px;
padding-left: 20px;
`

const error = css`
box-shadow: 0 0 10px rgba(255, 0, 0, 1);
`
