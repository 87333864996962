/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, isEmpty, map, size, difference, split } from 'lodash'
import linkifyHtml from 'linkifyjs/html'

import MainLayout from './MainLayout'
import MenuTop from './MenuTop'
import DefaultIconLabelButton from './DefaultIconLabelButton'
import BusyMask from './BusyMask'

import { organisationList } from '../actions/organisation'
import { articleList } from '../actions/article'
import { imageList } from '../actions/image'
import { userList } from '../actions/user'
import { requestPermissionIfNeeded, getToken, saveToken } from '../actions/token'
import { messageList } from '../actions/message'
import { messageReadList } from '../actions/message_read'

import { default_theme as theme } from '../emotion/theme'
import news_icon_light from '../images/news_outline_light.png'
import news_icon_dark from '../images/news_outline_dark.png'
import donate_icon_light from '../images/donate_outline_light.png'
import donate_icon_dark from '../images/donate_outline_dark.png'
import message_icon_light from '../images/message_outline_light.png'
import message_icon_dark from '../images/message_outline_dark.png'
import about_icon_light from '../images/about_outline_light.png'
import about_icon_dark from '../images/about_outline_dark.png'
import calendar_icon_light from '../images/calendar_light.png'
import calendar_icon_dark from '../images/calendar_dark.png'

class Landing extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(organisationList.fetchListIfNeeded())
        dispatch(articleList.invalidateList())
        dispatch(articleList.fetchListIfNeeded())
        dispatch(imageList.fetchListIfNeeded())
        dispatch(userList.invalidateList())
        dispatch(userList.fetchListIfNeeded())
        dispatch(messageList.invalidateList())
        dispatch(messageList.fetchListIfNeeded())
        dispatch(messageReadList.invalidateList())
        dispatch(messageReadList.fetchListIfNeeded())
    }
    componentDidUpdate(prevProps) {
        const { user, dispatch } = this.props
        if (JSON.stringify(prevProps.user) !== JSON.stringify(user) && !isEmpty(user)) {
            if (user.notifications) {
                requestPermissionIfNeeded().then((permission) => {
                    if (permission === 'granted') {
                        console.log('permission granted')
                        getToken()
                            .then((currentToken) => {
                                return dispatch(saveToken(currentToken))
                            }).catch((e) => {
                                console.log(e)
                            })
                    } else {
                        console.log('permission denied')
                        let values = Object.assign({}, user, { notification_method: 'text_message' })
                        const on_done = function () {
                            dispatch(userList.invalidateList())
                        }
                        return dispatch(userList.saveObject(values, on_done))
                    }
                })
            }
        }
    }

    onOpenArticle = () => {
        const { article, history } = this.props
        history.push('/article/' + article.id)
    }

    render() {
        const { header,
            organisation_logo,
            article,
            unread,
            logo_background,
            secondary,
            is_secondary_light,
            mobile_main_menu_option,
            history,
            is_loading,
            is_saving,

        } = this.props
        const body_parts = !isEmpty(article) ?
            split(linkifyHtml(article.body, { defaultProtocol: 'https' }), "\n") : []
        return (
            <MainLayout history={history}>
                {(is_loading || is_saving) && <BusyMask />}
                <div css={menu_top}>
                    <MenuTop
                        page="Home"
                        logo={organisation_logo}
                        history={history}
                    />
                </div>
                <div css={[logo, css`background:${logo_background};
                  @media (prefers-color-scheme: dark) {
                      background:${logo_background} !important;
                  }`]}>
                    <img src={organisation_logo} alt="" css={image} />
                    <label css={label}>{header}</label>
                </div>
                {article.heading &&
                    <div css={news}>
                        <div css={news_container} onClick={this.onOpenArticle}>
                            <label css={label}>Latest News</label>
                            <span css={todays_message}>
                                <p css={heading}>{article.heading}</p>
                                <div css={css`font-size: 16px;`}>
                                    {map(body_parts, function (v, k) {
                                        return (<p key={k} dangerouslySetInnerHTML={{ __html: v }}></p>)
                                    })}
                                </div>
                            </span>
                        </div>
                    </div>
                }
                <div css={container}>
                    <div css={[menu_bottom,
                        css`background:${secondary};
                                @media (prefers-color-scheme: dark) {
                                  background: ${secondary} !important;
                               }`]}>
                        <div css={[button_container, css`border: 1px solid ${is_secondary_light ? 'black' : 'white'};`]}>
                            <DefaultIconLabelButton
                                label="News"
                                icon_src={is_secondary_light ? news_icon_dark : news_icon_light}
                                to="/news"
                                color={is_secondary_light ? 'black' : 'white'}
                            />
                        </div>
                        <div css={[button_container, css`border: 1px solid ${is_secondary_light ? 'black' : 'white'};`]}>
                            {mobile_main_menu_option === 'events' ? (
                                <DefaultIconLabelButton
                                    label="Events"
                                    icon_src={is_secondary_light ? calendar_icon_dark : calendar_icon_light}
                                    to="/events"
                                    color={is_secondary_light ? 'black' : 'white'}
                                />
                            ) : (
                                <DefaultIconLabelButton
                                    label="Donate"
                                    icon_src={is_secondary_light ? donate_icon_dark : donate_icon_light}
                                    to="/donate"
                                    color={is_secondary_light ? 'black' : 'white'}
                                />
                            )}
                        </div>
                        <div css={[button_container, css`border: 1px solid ${is_secondary_light ? 'black' : 'white'};`]}>
                            <DefaultIconLabelButton
                                label="Messages"
                                icon_src={is_secondary_light ? message_icon_dark : message_icon_light}
                                to="/messages"
                                unread={unread}
                                color={is_secondary_light ? 'black' : 'white'}
                            />
                        </div>
                        <div css={[button_container, css`border: 1px solid ${is_secondary_light ? 'black' : 'white'};`]}>
                            <DefaultIconLabelButton
                                label="About"
                                icon_src={is_secondary_light ? about_icon_dark : about_icon_light}
                                to="/about"
                                color={is_secondary_light ? 'black' : 'white'}
                            />
                        </div>
                    </div>
                </div>
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const organisation = head(organisationList.getVisibleObjects()) || {}
    const article = head(articleList.getVisibleObjects()) || {}
    const user = head(userList.getVisibleObjects()) || {}
    let unread = 0
    if (!isEmpty(user)) {
        const messages = messageList.getVisibleObjects() || []
        const messages_read = messageReadList.getVisibleObjects() || []
        const messages_read_by_user = map(messages_read, (message_read) => {
            return message_read.message
        })
        const all_message_ids = map(messages, (message) => {
            return message.id
        })
        const unread_ids = difference(all_message_ids, messages_read_by_user)
        unread = size(unread_ids)
    }
    return {
        header: organisation.header,
        logo_background: organisation.mobile_logo_background_colour_hex,
        secondary: organisation.mobile_secondary_colour_hex,
        primary: organisation.mobile_primary_colour_hex,
        is_secondary_light: organisation.is_mobile_secondary_colour_light,
        is_primary_light: organisation.is_mobile_primary_colour_light,
        mobile_main_menu_option: organisation.mobile_main_menu_option,
        article,
        organisation_logo: organisation.thumbnail,
        unread,
        user,
        is_loading: organisationList.isLoading() || articleList.isLoading() || imageList.isLoading(),
        is_saving: userList.getIsSavingObject(),
    }
}
export default connect(mapStateToProps)(Landing)

const menu_top = css`
width: 100%;
height: 60px;
display: flex;
justify-content: center;
align-items: center;
background-color: white;
position: fixed;
left: 0;
top: 0;
z-index: 2;
@media(min-height: 1025px) {
height: 120px;
}
`

const logo = css`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
height: calc(100vh - 440px);
margin-top: 60px;
@media(min-height: 800px) {
height: calc(100vh - 510px);
}
@media(min-height: 850px) {
height: calc(100vh - 560px);
}
@media(min-height: 900px) {
height: calc(100vh - 610px);
}
@media(min-height: 950px) {
height: calc(100vh - 660px);
}
@media(min-height: 1025px) {
margin-top: 120px;
height: calc(100vh - 740px);
}
`

const image = css`
max-width: 80%;
max-height: 90%;
object-fit: contain;
`

const label = css`
font-size: 20px;
text-align: center;
text-transform: uppercase;
font-weight: 600;
`

const news = css`
width: 100%;
height: 180px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: ${theme.colors.light};
padding-top: 10px;
bottom: 200px;
@media(min-height: 800px) {
bottom: 225px;
height: 225px;
}
@media(min-height: 850px) {
bottom: 250px;
height: 250px;
}
@media(min-height: 900px) {
bottom: 275px;
height: 275px;
}
@media(min-height: 950px) {
bottom: 300px;
height: 300px;
}
@media(min-height: 1025px) {
    bottom: 300px;
    height: 320px;
}
`

const news_container = css`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
`

const todays_message = css`
font-size: 18px;
text-align: center;
width: 80%;
height: 130px;
overflow: hidden;
position: relative;
            &:after {
                content: "";
                text-align: right;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 40px;
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0), ${theme.colors.light} 70%);
            }
@media(min-height:800px) {
    height: 155px;
}
@media(min-height:850px) {
    height: 180px;
}
@media(min-height:900px) {
    height: 205px;
}
@media(min-height:950px) {
    height: 230px;
}
@media(min-height:1025px) {
    height: 250px;
}
`

const heading = css`
font-weight: 600;
`

const container = css`
display: flex;
justify-content: center;
width: 100%;
position: fixed;
left: 0;
bottom: 0;
`

const menu_bottom = css`
width: 100%;
height: 200px;
display: flex;
max-width: 1024px;
flex-wrap: wrap;
@media(min-height:800px) {
    height: 225px;
}
@media(min-height:850px) {
    height: 250px;
}
@media(min-height:900px) {
    height: 275px;
}
@media(min-height:950px) {
    height: 300px;
}
@media(min-height:1025px) {
    height: 300px;
}
`

const button_container = css`
height: 100px;
width: 50%;
@media(min-height:800px) {
    height: 112px;
}
@media(min-height:850px) {
    height: 125px;
}
@media(min-height:900px) {
    height: 137px;
}
@media(min-height:950px) {
    height: 150px;
}
`

