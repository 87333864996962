import facepaint from 'facepaint'

export const breakpoint_desktop_pixels = 1025
const breakpoint_desktop = `${breakpoint_desktop_pixels}px`

export const default_theme = {
    mq: facepaint([
        `@media(max-width: ${breakpoint_desktop})`
   ]),
    colors: {
        white: '#FFFFFF',
        light: '#f2f2f2',
        dark: '#494A4A',
        black: '#000000',
        primary: '#599DC2',
        primary_hover: '#3f6e88',
        secondary: '#FFFFFF',
        gray1: '#3c4858',
        gray2: '#e6e6e6',
        gray3: '#eeeeee',
        gray4: '#bfbfbf',
        gray5: '#7c7c7c',
        error: '#FF0000'
    }
}
