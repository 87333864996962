import { ItemList } from '../orm'
import { ENTITY_KEY__EVENT_FOLLOWER, LIST_KEY__EVENT_FOLLOWER } from './ItemListKeyRegistry'

class EventFollowerList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__EVENT_FOLLOWER
    }
}

export const eventFollowerList = new EventFollowerList(LIST_KEY__EVENT_FOLLOWER)
