/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { get, split, map, isEmpty } from 'lodash'
import MetaTags from 'react-meta-tags'
import linkifyHtml from 'linkifyjs/html'

import { articleList } from '../actions/article'
import { imageList } from '../actions/image'

import MainLayout from './MainLayout'
import MenuTop from './MenuTop'
import MenuBottom from './MenuBottom'
import Timestamp from './Timestamp'
import BusyMask from './BusyMask'

class Article extends Component {
    
    componentDidMount() {
        const { dispatch, article_id } = this.props
        dispatch(articleList.ensureObjectsLoaded([article_id]))
        dispatch(imageList.invalidateList())
        dispatch(imageList.fetchListIfNeeded())
    }

    render() {
        const { article, article_image, history, is_loading } = this.props
        const body_parts = !isEmpty(article) ? split(linkifyHtml(article.body, {defaultProtocol: 'https'}), "\n") : []
        return (
            <MainLayout history={ history }>
              <MetaTags>
                <meta name="twitter:card" content={ article_image ? 'summary_large_image' : 'summary'} />
                <meta name="twitter:title" content={ article.heading } />
                <meta name="twitter:description" content={ body_parts[0] } />
                { article_image &&
                    <meta name="twitter:image" content={ article_image } />
                }  
              </MetaTags>
              { is_loading && <BusyMask /> }
              <div css={ menu_top }>
                <MenuTop
                    page="News"
                    history={ history }
                />
              </div>
              <div css={ article_container }>
                { article_image &&
                  <div css={ image_container }>
                    <img src={ article_image } alt="" css={ image } />
                  </div>
                }
                <div css={ header_container }>
                  <label css={ label }>{ article.heading }</label>
                  <label css={ date }>
                    <Timestamp
                        value={ article.created_at }
                        format="date-long"
                    />
                  </label>
                </div>
                <hr css={ line } />
                <div css={ text_container }>
                  { map(body_parts, function(v, k) {
                        return (<p key={ k } dangerouslySetInnerHTML={{ __html: v }}></p>)
                    })}
                </div>
              </div>
              <div css={ menu_bottom }>
                <MenuBottom
                    page="News"
                />
              </div>
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const article_id = get(props, ["match", "params", "article_id"], null)
    const article = articleList.getObject(article_id) || {}
    const image = imageList.getObject([article.image_id]) || {}
    return {
        article_id,
        article,
        article_image: image.thumbnail_download_url,
        is_loading: articleList.isLoading() || imageList.isLoading()
    }
}
export default connect(mapStateToProps)(Article)

const menu_top = css`
width: 100%;
height: 60px;
display: flex;
justify-content: center;
align-items: center;
background-color: white;
position: fixed;
left: 0;
top: 0;
z-index: 2;
@media(min-height: 1025px) {
height: 120px;
}
`

const article_container = css`
flex-grow: 1;
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
background: white;
margin-top: 60px;
margin-bottom: 60px;
@media(min-height: 1025px) {
margin-top: 120px;
margin-bottom: 120px;
}
`

const image_container = css`
background: white;
`

const image = css`
max-width: 100%;
object-fit: contain;
max-height: 150px;
`

const header_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
`

const label = css`
font-size: 22px;
text-align: left;
line-height: 24px;
width: 90%;
padding-top: 15px;
`

const date = css`
font-family: 'Montserrat', sans-serif;
font-size: 14px;
letter-spacing: 2.8px;
font-weight: 600;
text-align: left;
text-transform: uppercase;
width: 90%;
color: #7c7c7c;
`

const line = css`
width: 85%;
margin: 0;
margin-bottom: 10px;
`

const text_container = css`
width: 85%;
font-size: 16px;
`


const menu_bottom = css`
width: 100%;
height: 60px;
display: flex;
align-items: center;
background-color: white;
position: fixed;
left: 0;
bottom: 0;
@media(min-height: 1025px) {
height: 120px;
}
`
