import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux'

import MainRouter from './components/MainRouter'
import configureStore from './store/configureStore'
import { syncORMWithStore } from './orm'

export const store = configureStore({})
syncORMWithStore(store)

class App extends Component {

    render() {
        return (
              <Provider store={store}>
                <Router>
                  <MainRouter />
                </Router>
              </Provider>
        );
    }
}

if (document.getElementById('root').hasChildNodes()) {
    ReactDOM.hydrate(<App />, document.getElementById('root'));
} else {
    ReactDOM.render(<App />, document.getElementById('root'));
}

