/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css, Global } from '@emotion/core'
import { Link } from 'react-router-dom'
import { head, get, isEmpty } from 'lodash'
import { detect } from 'detect-browser'

import { organisationList } from '../actions/organisation'
import { imageList } from '../actions/image'
import { userList } from '../actions/user'

import DefaultIconButton from './DefaultIconButton'
import ToggleButton from './ToggleButton'
import BusyMask from './BusyMask'

import { default_theme as theme } from '../emotion/theme'
import burger from '../images/burger_dark.png'
import close_light from '../images/close_light.png'
import close_dark from '../images/close_icon.png'
import register_light from '../images/register_outline_light.png'
import register_dark from '../images/register_outline_dark.png'
import about_light from '../images/about_outline_light.png'
import about_dark from '../images/about_outline_dark.png'
import feedback_light from '../images/feedback_outline_light.png'
import feedback_dark from '../images/feedback_outline_dark.png'
import donate_light from '../images/donate_outline_light.png'
import donate_dark from '../images/donate_outline_dark.png'

class MenuTop extends Component {

    constructor(props) {
        super(props)
        this.state = {
            notifications: null,
            notification_method: null,
        }
    }

    componentDidMount() {
        const { dispatch, user } = this.props
        dispatch(organisationList.fetchListIfNeeded())
        dispatch(imageList.fetchListIfNeeded())
        dispatch(userList.fetchListIfNeeded())
        this.setState({
            notifications: user.notifications,
            notification_method: user.notification_method,
        })
    }

    componentDidUpdate(prevProps) {
        const { user } = this.props
        const { notifications, notification_method } = this.state
        if (!isEmpty(user) &&
            notifications !== user.notifications) {
            this.setState({notifications: user.notifications})
        }
        if (!isEmpty(user) &&
            notification_method !== user.notification_method) {
            this.setState({notification_method: user.notification_method})
        }
    }

    goBack = () => {
        const { history } = this.props
        history.goBack()
    }
    
    toggleMenu = () => {
        const burger = document.getElementById('burger')
        burger.classList.toggle('is-nav-open')
    }

    onChangeToggle = (value) => {
        const { dispatch, user } = this.props
        const { notification_method } = this.state
        this.setState({notifications:value})
        const on_done = function() {
            dispatch(userList.invalidateList())
        }
        dispatch(userList.saveObject(Object.assign({},
                                                   user,
                                                   {notifications: value},
                                                   {notification_method: notification_method ?
                                                                         'push_notification' :
                                                                         'text_message'}),
                                     on_done))
    }

    onChangeMethodToggle = (value) => {
        const { dispatch, user } = this.props
        this.setState({notification_method:value})
        const on_done = function() {
            dispatch(userList.invalidateList())
        }
        dispatch(userList.saveObject(Object.assign({},
                                                   user,
                                                   {notification_method: value ?
                                                                         'push_notification' :
                                                                         'text_message'}),
                                     on_done))
    }

    renderMenu = () => {
        const { logo,
                logo_background,
                organisation,
                primary,
                is_primary_light,
                user } = this.props
        const { notifications, notification_method } = this.state
        const menu_label = is_primary_light ? menu_label_dark : menu_label_light
        const browser = detect()
        return (
            <div css={ burger_container } id="burger" className="burger">
              <div css={ [
                  burger_menu,
                  css`background: ${primary};
                      @media (prefers-color-scheme: dark) {
                        background: ${primary} !important;
                      }`
              ] }>
                <div css={ close_container }>
                  <DefaultIconButton
                      icon_src={ is_primary_light ? close_dark : close_light }
                      onClickButton={ () => this.toggleMenu() }
                      extra_class={ icon }
                  />
                </div>
                <div css={ menu_item_container }>
                  <div css={ menu_item }>
                    <Link
                        to="/register"
                        css={ link }
                    >
                      <img src={ is_primary_light ? register_dark : register_light } css={ menu_icon } alt="" />
                      <label css={ menu_label }>{ !isEmpty(user) ? "Profile" : "Register" }</label>
                    </Link>
                  </div>
                  <div css={ menu_item }>
                    <Link
                        to="/about"
                        css={ link }
                    >
                      <img src={ is_primary_light ? about_dark : about_light } css={ menu_icon } alt="" />
                      <label css={ menu_label }>About</label>
                    </Link>
                  </div>
                  <div css={ menu_item }>
                    <Link
                        to="/feedback"
                        css={ link }
                    >
                      <img src={ is_primary_light ? feedback_dark : feedback_light } css={ menu_icon } alt="" />
                      <label css={ menu_label }>Feedback</label>
                    </Link>
                  </div>
                  {get(organisation, "show_donations_in_side_bar") === true && ( 
                    <div css={ menu_item }>
                      <Link
                        to="/donate"
                        css={ link }
                      >
                        <img src={ is_primary_light ? donate_dark : donate_light } css={ menu_icon } alt="" />
                        <label css={ menu_label }>Donate</label>
                      </Link>
                    </div>
                  )}
                  </div>
                { !isEmpty(user) &&
                  <div css={ menu_item_toggle }>
                    <ToggleButton
                        value={ notifications }
                        onChange={ this.onChangeToggle }
                        on_label="Notifications On"
                        off_label="Notifications Off"
                    />
                  </div>
                }
                { !isEmpty(user) && user.notifications && browser && browser.name !== 'ios' &&
                  <div css={ menu_item_toggle }>
                    <ToggleButton
                        value={ notification_method }
                        onChange={ this.onChangeMethodToggle }
                        on_label="Push Notification"
                        off_label="Text Message"
                    />
                  </div>
                }
                <div css={ [menu_logo_container, css`background:${logo_background};
                  @media (prefers-color-scheme: dark) {
                      background:${logo_background} !important;
                  }`] }>
                  <img src={ logo } css={ menu_logo } alt="" />
                </div>
              </div>
              <div css={ empty_container } onClick={ () => {this.toggleMenu()} }></div>
            </div>
        )
    }
    
    render() {
        const { page, is_busy } = this.props
        return (
            <div css={ menu }>
              { is_busy && <BusyMask /> }
              <Global styles={ global } />
              <div css={css`display:flex;width:100%;`}>
                <div css={ icon_container }>
                  <DefaultIconButton
                      icon_src={ burger }
                      onClickButton={ () =>  this.toggleMenu() }
                      extra_class={ icon }
                      icon_height={ '35px' }
                  />
                </div>
                <div css={ back_container } onClick={ () => this.goBack() }>
                  <label css={ label }>Back</label>
                </div>
              </div>
              <label css={ label }>{ page }</label>
              { this.renderMenu() }
            </div>
        )
    }
}
function mapStateToProps(state, props) {
    const { page } = props
    const organisation = head(organisationList.getVisibleObjects()) || {}
    let user = head(userList.getVisibleObjects()) || {}
    if (!isEmpty(user)) {
        user = Object.assign({},
                             user,
                             {notification_method: user.notification_method === 'push_notification' ?
                                                   true : false})
    }
    
    return {
        page,
        logo: organisation.thumbnail,
        logo_background: organisation.mobile_logo_background_colour_hex,
        primary: organisation.mobile_primary_colour_hex,
        is_primary_light: organisation.is_mobile_primary_colour_light,
        user,
        organisation,
        is_busy: organisationList.isLoading() ||
                 imageList.isLoading() ||
                 userList.isLoading() ||
                 userList.getIsSavingObject(),
    }
}
export default connect(mapStateToProps)(MenuTop)

const global = css`
.burger.is-nav-open{
left: 0;
transition: all 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
}
`

const menu = css`
width: 100%;
padding-left: 20px;
padding-right: 20px;
display: flex;
justify-content: space-between;
align-items: center;
max-width: 1024px;
`

const icon_container = css`
height: 100%;
display: flex;
justify-content: center;
align-items: center;
`

const icon = css`
height: 100%;
display: flex;
justify-content: center;
align-items: center;
`

const label = css`
font-family: 'Montserrat', sans-serif;
text-transform: uppercase;
font-weight: 600;
letter-spacing: 2.8px;
font-size: 14px;
margin: 0;
@media(min-height:1025px) {
font-size: 28px;
letter-spacing: 5.2px;
}
`

const burger_menu = css`
text-align: center;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
width: 80vw;
height: 100vh;
box-shadow: 1vw 1vw 3vw 0px ${theme.colors.dark};
@media(min-height:1025px) {
width: 40vw;
}
`

const burger_container = css`
position: fixed;
top: 0;
left: -100vw;
overflow-x: hidden;
transition: all 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
display:flex;
width:100vw;
}
`

const close_container = css`
height: 60px;
width: 100%;
padding-left: 20px;
display: flex;
justify-content: flex-start;
align-items: center;
@media(min-height:1025px) {
height: 120px;
}
`

const menu_item_container = css`
height: 50%;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
`

const menu_item = css`
display: flex;
flex-direction: row;
justify-items: flex-start;
align-items: center;
padding-left: 20px;
height: 20%;
width: 100%;
`

const menu_item_toggle = css`
height: 10%;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
`

const menu_logo_container = css`
height: 27%;
width: 100%;
display: flex;
flex-direction: row;
justify-items: center;
align-items: center;
`

const link = css`
color: white;
display: flex;
flex-direction: row;
align-items: center;
height: 100%;
width: 100%;
`

const menu_icon = css`
max-height: 40%;
`

const menu_label_light = css`
padding-left: 7%;
margin-top: 10px;
font-family: 'Montserrat', sans-serif;
text-transform: uppercase;
font-weight: 600;
letter-spacing: 3px;
font-size: 18px;
color: white;
@media(min-height: 1025px) {
font-size: 30px;
letter-spacing: 6px;
}
`

const menu_label_dark = css`
padding-left: 7%;
margin-top: 10px;
font-family: 'Montserrat', sans-serif;
text-transform: uppercase;
font-weight: 600;
letter-spacing: 3px;
font-size: 18px;
color: black;
@media(min-height: 1025px) {
    font-size: 30px;
    letter-spacing: 6px;
}
`

const menu_logo = css`
max-width: 80%;
max-height: 80%;
margin-left: auto;
margin-right: auto;
object-fit: contain;
`

const empty_container = css`
height:100vh;
width:20vw;
@media(min-height: 1025px) {
width: 70vw;
}
`

const back_container = css`
display: flex;
align-items: center;
margin-left: 20px;
cursor: pointer;
label{
    cursor: pointer;
}
`
