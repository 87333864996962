import { ItemList } from '../orm'
import { ENTITY_KEY__ORGANISATION, LIST_KEY__ORGANISATION } from './ItemListKeyRegistry'

class OrganisationList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__ORGANISATION
    }
}

export const organisationList = new OrganisationList(LIST_KEY__ORGANISATION)

class FeedbackEmailList extends ItemList {
    getEntityKey() {
        return 'organisation/send_feedback_email'
    }

    send(values, on_done) {
        return this.saveNewObject(values, on_done)
    }
}

export const feedbackEmailList = new FeedbackEmailList('organisation/send_feedback_email')
