/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { head } from 'lodash'

import { organisationList } from '../actions/organisation'

import { jsx, css } from '@emotion/core'

class DefaultButton extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(organisationList.fetchListIfNeeded())
    }
    
    renderButton = () => {
        const {
            extra_class,
            label,
            onClickButton,
            disabled,
            type,
            light,
            custom_styles,
            primary,
            is_primary_light,
            secondary,
            is_secondary_light,
            button_type,
        } = this.props;
        const button_color = button_type === 'secondary' ? secondary : primary
        const is_button_light = button_type === 'secondary' ? is_secondary_light : is_primary_light
        return (
            <button
                css={ [ extra_class,
                        main_button,
                        css`background:${button_color};
                            @media (prefers-color-scheme: dark) {
                              background:${button_color} !important;
                            }`,
                        is_button_light ? css`color:black;` : css`color:white;` ] }
                style={custom_styles}
                className={light ? "btn btn-light" : "btn"}
                disabled={disabled}
                onClick={!disabled ? onClickButton : function() {}} 
                type={type}>
              { label && label }
              {this.props.children}
            </button>
        )
    }

    renderLink = () => {
        const {
            extra_class,
            label,
            onClickButton,
            disabled,
            to,
            target,
            custom_styles,
            primary,
            secondary,
            id,
            button_type,
        } = this.props;
        
        return(
            <Link
                to={to}
                css={
                [ extra_class,
                  button_type === 'secondary' ?
                  css`color:${secondary};
                      @media (prefers-color-scheme: dark) {
                        color:${secondary} !important;
                      }` :
                  css`color:${primary};
                      @media (prefers-color-scheme: dark) {
                        color:${primary} !important;
                      }`,
                  main_link ]
                }
                style={custom_styles}
                onClick={!disabled ? onClickButton : function() {}}
                target={target}
                id={id}
            >
              {label && label}
            </Link>
        )
    }
    
    render() {
        const { to } = this.props;
        
        if(to) {
            return this.renderLink()
        } else {
            return this.renderButton()
        }
    }
}
function mapStateToProps(state, props) {
    const organisation = head(organisationList.getVisibleObjects()) || {}
     return {
         primary: organisation.mobile_primary_colour_hex,
         is_primary_light: organisation.is_mobile_primary_colour_light,
         secondary: organisation.mobile_secondary_colour_hex,
         is_secondary_light: organisation.is_mobile_secondary_colour_light,
    }
}
export default connect(mapStateToProps)(DefaultButton)

const main_button = css`
text-transform: uppercase;
border: none;
border-radius: 20px;
padding: 12px;
padding-left: 20px;
font-size: 20px;
font-family: 'Montserrat', sans-serif;
letter-spacing: 10;
font-weight: 600;
`

const main_link = css`
text-decoration: underline;
`
