/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'

import ReactPortal from './ReactPortal'

class Prompt extends Component {

    render() {
        const { children, close_icon, close_background, onClose } = this.props
        return (
            <ReactPortal>
              <div css={ main }>
                <div css={ container }>
                  <div
                      css={ [
                          prompt_close,
                          css`background:${close_background};
                              @media (prefers-color-scheme: dark) {
                                background: ${close_background} !important;
                                }`
                      ] }
                    
                      onClick={ onClose }
                  >
                    <img
                        src={ close_icon }
                        alt=""
                        css={ close_image } />
                  </div>
                  <div css={ prompt } >
                    { children }
                  </div>
                </div>
              </div>
            </ReactPortal>
        )
    }
}
export default Prompt

const main = css`
position: fixed;
z-index: 999;
top: 0;
left: 0;
height: 100%;
width: 100%;
overflow: auto;
background-color: rgba(0,0,0,0.4);
`

const container = css`
position: relative;
display: flex;
flex: 1;
align-items: center;
flex-direction: column;
justify-content: space-between;
margin: 40% auto;
width: 90vw;
max-width: 500px;
border-radius: 5px;
background-color: #FFFFFF;
`

const prompt_close = css`
align-self: flex-end;
margin-top: -10px;
margin-right: -10px;
border-radius: 50%;
height: 30px;
width: 30px;
display: flex;
justify-content: center;
align-items: center;
background: white;
position: relative;
cursor: pointer;
`

const close_image = css`
max-height: 70%;
max-width: 70%;
`

const prompt = css`
display:flex;
flex-direction: column;
width: 100%;
height: 90%;
justify-content:center;
align-items:center;
`
