/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field } from 'redux-form'

import InputField from './InputField'
import InputArea from './InputArea'
import DefaultButton from './DefaultButton'

import { default_theme as theme } from '../emotion/theme'

const required = value => value ? undefined : 'Required'

class FeedbackForm extends Component {
    
    render() {
        const { handleSubmit, error, toggleMenu } = this.props

        return (
            <form css={ form_layout } onSubmit={ handleSubmit }>
              <div css={ field_container }>
                <Field
                    type="text"
                    name="name"
                    component={ InputField }
                    validate={[required]}
                    onFocus={ toggleMenu }
                    onBlur={ toggleMenu }
                    placeholder="Name"
                />
              </div>
              <div css={ field_container }>
                <Field
                    type="text"
                    name="message"
                    component={ InputArea }
                    validate={[required]}
                    onFocus={ toggleMenu }
                    onBlur={ toggleMenu }
                    rows={ 5 }
                    placeholder="Your Message"
                />
              </div>
              <div css={ field_container }>
                <DefaultButton
                    label="Send"
                    type="submit"
                />
              </div>
              <div css={ error_container }>
                { error && <p>{ error }</p>}
              </div>
            </form>
        )
    }
}
FeedbackForm = reduxForm({
    form: 'FEEDBACK_FORM'
})(FeedbackForm);
function mapStateToProps(state, props) {
    const { onSubmit, toggleMenu } = props
    return {
        onSubmit,
        toggleMenu,
    }
}
export default withRouter(connect(mapStateToProps)(FeedbackForm))

const form_layout = css`
width: 65%;
display: flex;
flex-direction: column;
align-items: center;
`

const field_container = css`
width: 100%;
display:flex;
flex-direction: column;
padding: 5px;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 50px;
flex-direction: column;
color: ${theme.colors.error};
`
