import { ItemList } from '../orm'
import { ENTITY_KEY__MESSAGE, LIST_KEY__MESSAGE } from './ItemListKeyRegistry'

class MessageList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__MESSAGE
    }
}

export const messageList = new MessageList(LIST_KEY__MESSAGE)

class MessageUnreadList extends ItemList {
    getEntityKey() {
        return 'message_unread'
    }
}

export const messageUnreadList = new MessageUnreadList('message_unread')

class ReadMessageIdsList extends ItemList {
    getEntityKey() {
        return 'message/get_read_message_ids'
    }

    fetch() {
        return this.saveNewObject()
    }
}

export const readMessageIdsList = new ReadMessageIdsList('message/get_read_message_ids')
