/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { jsx } from '@emotion/core'

import Splash from './Splash'
import Landing from './Landing'
import About from './About'
import News from './News'
import Article from './Article'
import Register from './Register'
import RegistrationComplete from './RegistrationComplete'
import Messages from './Messages'
import Donate from './Donate'
import Events from './Events'
import EventDetail from './EventDetail'
import Feedback from './Feedback'
import FeedbackConfirmation from './FeedbackConfirmation'
import DonationComplete from './DonationComplete'
import DonationAccepted from './DonationAccepted'
import DonationDeclined from './DonationDeclined'

import { isLoggedIn, loggedInUser } from '../actions/auth'

class AuthRouter extends Component {

    render() {
        let user = {}
        if (isLoggedIn()) {
            user = loggedInUser()
        }
        
        return (
            <div>
              <Switch>
                <Route path="/splash" exact={ true } component={ Splash } />
                <Route path="/landing" exact={ true } component={ Landing } />
                <Route path="/about" exact={ true } component={ About } />
                <Route path="/news" exact={ true } component={ News } />
                <Route path="/article/:article_id" component={ Article } />
                <Route path="/register" exact={ true } component={ Register } />
                <Route path="/complete_registration/:user_id" component={ RegistrationComplete } />
                <Route path="/messages" exact={ true } component={ (props) => <Messages user={ user } {...props} /> } />
                <Route path="/donate" exact={ true } component={ Donate } />
                <Route path="/events" exact={ true } component={ Events } />
                <Route path="/event/:event_id" exact={ true } component={ EventDetail } />                
                <Route path="/complete_donation/:status" component={ DonationComplete } />
                <Route path="/feedback" exact={ true } component={ Feedback } />
                <Route path="/feedback_confirmation" exact={ true } component={ FeedbackConfirmation } />
                <Route path="/donation_accepted" exact={ true } component={ DonationAccepted } />
                <Route path="/donation_declined" exact={ true } component={ DonationDeclined } />
                <Route path="/" component={ Splash } />
              </Switch>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {}
}
export default withRouter(connect(mapStateToProps)(AuthRouter))
