import { ItemList } from '../orm'
import { ENTITY_KEY__PAYNOW_MERCHANT, LIST_KEY__PAYNOW_MERCHANT } from './ItemListKeyRegistry'

class PayNowMerchantList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__PAYNOW_MERCHANT
    }
}

export const payNowMerchantList = new PayNowMerchantList(LIST_KEY__PAYNOW_MERCHANT)
