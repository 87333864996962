
import { SubmissionError } from 'redux-form'
import { has, isEmpty, size } from 'lodash'
import * as http from '../lib/http'
import cookie from 'react-cookies'

export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN"
export const CLEAR_AUTH_TOKEN = "CLEAR_AUTH_TOKEN"
export const USER_LOGGED_IN = "USER_LOGGED_IN"
export const ANNOUNCE_LOGGING_IN_USER = "ANNOUNCE_LOGGING_IN_USER"
export const ANNOUNCE_USER_NOT_LOGGED_IN = "ANNOUNCE_USER_NOT_LOGGED_IN"
export const USER_REGISTERED = "USER_REGISTERED"
export const ANNOUNCE_REGISTERING_USER = "ANNOUNCE_REGISTERING_USER"
export const ANNOUNCE_USER_NOT_REGISTERED = "ANNOUNCE_USER_NOT_REGISTERED"

export function clearAuthentication() {
    cookie.remove('authToken', { path: '/' })
    cookie.remove('user', { path: '/' })
    return {
        type: CLEAR_AUTH_TOKEN
    }
}

export function announceUserNotLoggedIn() {
    return {
        type: ANNOUNCE_USER_NOT_LOGGED_IN
    }
}

export function announceUserLoggedIn(token, user) {
    cookie.save('authToken', token, { path: '/' })
    cookie.save('user', user, { path: '/' })
    localStorage.setItem('followerAuthToken', user.auth_token)
    return {
        type: USER_LOGGED_IN,
        token,
        user
    }
}

export function announceLoggingInUser() {
    return {
        type: ANNOUNCE_LOGGING_IN_USER
    }
}

export function announceRegisteringUser() {
    return {
        type: ANNOUNCE_REGISTERING_USER
    }
}

export function announceUserRegistered() {
    return {
        type: USER_REGISTERED
    }
}

export function announceUserNotRegistered() {
    return {
        type: ANNOUNCE_USER_NOT_REGISTERED
    }
}

export function login(email, password) {                                                                           
    return async (dispatch, getState) => {                                                                          
        try {
            dispatch(announceLoggingInUser())                                                                       
            let [json, success] = await http.post(getState(), 'login/login', { email: email, password: password })
            if (success.status === 200 && has(json, 'token')) {
                dispatch(announceUserLoggedIn(json.token, json.user || {}))                                         
            } else {
                dispatch(announceUserNotLoggedIn())                                                                 
                throw new SubmissionError({_error: "Failed to Login with your Credentials"}) 
            }                                        
        } catch (e) {                                                                                              
            if (e instanceof SubmissionError) {                                                                    
                throw e
            } else {
                throw new SubmissionError({_error: 'Login failed ' + e.message})
            }                                                                                                      
        }                                                                                                          
    }
}

export function register(values, on_done) {                    
    return async (dispatch, getState) => {                                                                          
        try {                                                                                                       
            dispatch(announceRegisteringUser())
            let [json, success] = await http.post(getState(), 'register', values)
            if (success.status === 200 && has(json, 'user')) {
                dispatch(announceUserRegistered())
                on_done(json)
            } else {
                dispatch(announceUserNotRegistered())
                throw new SubmissionError({_error: "Failed to Register Credentials"})
            }
            if (!json.success) {
                throw new SubmissionError({_error: json.message})
            }
        } catch (e) {
            if (e instanceof SubmissionError) {
                throw e
            } else {
                throw new SubmissionError({_error: 'Registration failed ' + e.message})
            }
        }
    }
}

export function isLoggedIn() {
    const token = authToken()
    return ! isEmpty(token) && size(token) > 0
}

export function authToken() {
    return cookie.load('authToken')
}

export function loggedInUser() {
    return cookie.load('user')
}
