import { ItemList } from '../orm'
import { ENTITY_KEY__USER, LIST_KEY__USER } from './ItemListKeyRegistry'

class UserList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__USER
    }
}

export const userList = new UserList(LIST_KEY__USER)

class OTPTextMessageList extends ItemList {
    getEntityKey() {
        return 'notification/send_otp_text_message'
    }

    send(id, on_done) {
        return this.saveNewObject({user_id: id}, on_done)
    }
}

export const otpTextMessageList = new OTPTextMessageList('notification/send_otp_text_message')

class UserOrganisationList extends ItemList {
    getEntityKey() {
        return 'user/verify_phone_number'
    }

    verifyPhoneNumber(values, on_done) {
        return this.saveNewObject(values, on_done)
    }
}

export const userOrganisationList = new UserOrganisationList('user/link_user_with_organisation')

class UserValidationList extends ItemList {
    getEntityKey() {
        return 'user/async_validation'
    }

    validate(values, on_done) {
        return this.saveNewObject(values, on_done)
    }
}

export const userValidationList = new UserValidationList('user/async_validation')


class UserProfileList extends ItemList {
    getEntityKey() {
        return 'user/update_profile'
    }

    update(values, on_done) {
        return this.saveNewObject(values, on_done)
    }
}

export const userProfileList = new UserProfileList('user/update_profile')
