/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'

import FormError from './FormError'

class InputField extends Component {
    
    render() {
        const {
            extra_class,
            placeholder,
            input,
            label,
            type,
            tabIndex,
            meta,
            ...extraProps
        } = this.props
        return (
            <div>
              <input {...input}
                     {...extraProps}
                     placeholder={placeholder}
                     className="form-control"
                     css={[extra_class, main, meta.error && meta.touched && !meta.active && error]}
                     type={type}
              />
              { meta.error && meta.touched && !meta.active &&
                <FormError meta={ meta } />
              }
            </div>
        )
    }
}
export default InputField;



const main = css`
border: none;
border-radius: 20px;
padding: 12px;
padding-left: 20px;
@media (prefers-color-scheme: dark) {
background: white !important;
}
`

const error = css`
box-shadow: 0 0 10px rgba(255, 0, 0, 1);
`
